@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');
* {
  margin: 0;
  padding: 0;
  font-family: "Poppins";
}

:root {
    --primary: #FEB25E;
    --secondary: rgb(45, 54, 59);
    --light: rgb(124, 134, 141);
    --dark-text: #354047;
    --s-dark-text: rgb(37, 44, 48);
}

.pressed:focus {
  outline:none !important;
  outline-width: 0 !important;
  box-shadow: none !important;
  -moz-box-shadow: none !important;
  -webkit-box-shadow: none !important;
  border-color: transparent !important;
}

.popup-title {
  position: absolute !important;
  bottom: 50px !important;
  left: 0 !important;
  right: 0 !important;
  margin-left: auto !important;
  margin-right: auto !important;
  width: fit-content !important;
  background-color: #1d2327;
  color: rgb(247, 252, 255);
  border-radius: 20px !important;
  padding: 14px 35px !important;
  font-size: 14px !important;
  line-height: 14px !important;
  transition-timing-function: linear !important;
  z-index: 2000;

  i {
    font-size: 14px !important;
  }
}

@keyframes firework {
  0%{transform: scale(0); opacity: 1;}
  20%{transform: scale(0); opacity: 1;}
  50%{opacity: 1;}
  100%{transform: scale(1); opacity: 0;}
}

@keyframes wave {
  0% {transform: rotate(0deg);}
  100% {transform: rotate(360deg);}
}

@keyframes gradient {
  0%{background-position: 100% 100%}
  50%{background-position: 0% 0%}
  100%{background-position: 100% 100%}
}

@media (max-width: 1336px) {
  .styleInicio {
    background-image: none;
    background-size: cover;
    background-attachment: fixed;
    background-repeat: no-repeat;
    height: 100vh;
    width: 100% !important;
    overflow-x: hidden !important;
    overflow-y: scroll;
    overflow: hidden;

    body {
      background-image: none;
      background-size: cover;
      background-attachment: fixed;
      background-repeat: no-repeat;
      height: 100vh;
      width: 100% !important;
      overflow-x: hidden !important;
      overflow-y: scroll;
    }
  
    nav {
      display: none;
    }
  
    /*------------------------------------------------------------------------------*/
    /*----------------------------------- MENU --------------------------------------*/
    /*------------------------------------------------------------------------------*/
    #minimal-menu {
      background-color: #3C484F;
      width: 100%;
      height: 90px;
      overflow: hidden;
      transition: height 0.6s;
    }
  
    #minimal-menu .top-menu {
      height: 90px;
      display: flex;
      align-items: center;
    }
  
    #minimal-menu .top-menu i {
      font-size: 30px;
      width: 30px;
      padding: 20px;
      color: white;
    }
  
    #minimal-menu .top-menu #logo-mm {
      width: calc(100vw - 70px - 200px);
    }
  
    #minimal-menu .top-menu #button-mm {
      background-color: #212121;
      width: 200px;
      color: white;
      border-radius: 2px;
      border-left: 3px solid #FEB25E;
      overflow: hidden;
      text-decoration: none;
    }
  
    #minimal-menu .top-menu #button-mm h3 {
      text-align: right;
      padding-right: 11px;
      font-size: 0.5rem;
      padding-top: 3px;
      color: #FEB25E;
    }
  
    #minimal-menu .top-menu #button-mm h4 {
      text-align: center;
    }
  
    #minimal-menu .top-menu #button-mm h5 {
      font-size: 0.5rem;
      position: relative;
      top: -8px;
      left: 53%;
    }
  
    #minimal-menu .top-menu #button-mm h6 {
      text-align: center;
      font-size: 0.6rem;
      font-weight: 100;
      margin-top: -5px;
      padding-bottom: 4px;
    }
  
    #minimal-menu .top-menu img {
      width: 70px;
      padding-left: 20px;
    }
  
    #minimal-menu .container-menu {
      display: flex;
      flex-direction: column;
      justify-content: center;
      background-color: #2d363b;
      height: 240px;
    }
  
    #minimal-menu .container-menu a {
      text-align: center;
      padding: 15px;
      color: white;
      text-decoration: none;
    }
  
    .max-min-menu {
      height: 330px !important;
    }
    /*------------------------------------------------------------------------------*/
    /*------------------------------------------------------------------------------*/
    /*------------------------------------------------------------------------------*/
  
    /* --- --- --- --- --- --- --- --- Home --- --- --- --- --- --- --- --- */
  
    header {
      background-color: rgba(0, 0, 0, 0.733);
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  
    header #marcadorInfoSm {
      color: white;
      text-align: center;
      width: 100%;
      display: flex;
      align-items: center;
      flex-direction: column;
      --animate-duration: 1.1s;
      padding-top: 40px;
      padding-bottom: 40px;
      background-color: #2d363b;
    }
  
    header #marcadorInfoSm h1 {
      font-size: 3rem;
      padding-bottom: 0.5rem;
    }
  
    header #marcadorInfoSm h1 p {
      position: absolute;
      font-size: 17px;
      margin-top: -1rem;
      margin-left: 168px;
    }
  
    header #marcadorInfoSm h3 {
      font-size: 1.2rem;
      font-weight: 700;
      padding-top: 50px;
    }
  
    header #marcadorInfoSm h3 b {
      font-weight: 400;
      font-size: 12pt;
    }
  
    header #marcadorInfoSm a {
      margin-top: 1.2rem;
      font-size: 1rem;
      padding: 0.7rem 2rem;
      cursor: pointer;
      font-weight: 900;
      color: var(--s-dark-text);
      background-color: var(--primary);
      text-decoration: none;
    }
  
    /* +-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+ */
  
    header #torneoInfoSm {
      padding-top: 40px;
      padding-bottom: 40px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: white;
      width: 100%;
      --animate-duration: 1.1s;
    }
  
    #torneoInfoSm * {
      z-index: 2;
    }
  
    #torneoInfoSm i {
      position: absolute;
      color: #ffffff46;
      font-size: 12.5rem;
      z-index: 1;
      margin-top: 70px;
    }
  
    #torneoInfoSm h1 {
      font-size: 3rem;
      padding-bottom: 1.5rem;
    }
  
    #torneoInfoSm h1 p {
      position: absolute;
      font-size: 17px;
      margin-top: -1.2rem;
      margin-left: 188px;
    }
  
    #torneoInfoSm h3 {
      font-size: 1.2rem;
      font-weight: 700;
      padding-bottom: 45px;
      text-align: center;
    }
  
    #torneoInfoSm h4 {
      font-size: 1rem;
      margin-top: 25px;
      margin-bottom: 7px;
    }
  
    #torneoInfoSm #timeCardsContainer {
      display: flex;
    }
  
    #torneoInfoSm #timeCardsContainer div {
      padding: 10px;
      padding-top: 0px;
      text-align: center;
    }
  
    #torneoInfoSm #timeCardsContainer div h1 {
      font-size: 2.7rem;
      width: 80px !important;
      background: rgba(0, 0, 0, 0.5);
      padding-bottom: unset;
    }
  
    #torneoInfoSm #timeCardsContainer div h5 {
      font-size: 0.6rem;
      background: rgba(0, 0, 0, 0.5);
      padding-bottom: 8px;
    }
  
    #torneoInfoSm a {
      margin-top: 50px;
      display: block;
      font-size: 1rem;
      padding: 0.7rem 1.3rem;
      cursor: pointer;
      font-weight: 900;
      color: var(--s-dark-text);
      background-color: var(--primary);
      text-decoration: none;
      margin-bottom: 15px;
    }
  
    /* +-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+ */
  
    header #serviciosInfoSm {
      color: white;
      text-align: center;
      width: 100%;
      display: flex;
      align-items: center;
      flex-direction: column;
      --animate-duration: 1.1s;
      background-color: #212121;
      padding-top: 40px;
      padding-bottom: 40px;
    }
  
    header #serviciosInfoSm h1 {
      font-size: 3rem;
      padding-bottom: 0.5rem;
    }
  
    header #serviciosInfoSm h1 p {
      position: absolute;
      font-size: 17px;
      margin-top: -1rem;
      margin-left: 179px;
    }
  
    header #serviciosInfoSm div {
      width: 400px;
      display: flex;
    }
  
    header #serviciosInfoSm div i {
      padding-top: 25px;
      font-size: 47px;
      width: 77px;
      padding-left: 10px;
      padding-right: 20px;
    }
  
    header #serviciosInfoSm div h3 {
      font-size: 1rem;
      font-weight: 700;
      padding-top: 25px;
      text-align: left;
    }
  
    header #serviciosInfoSm div h3 b {
      font-weight: 400;
      font-size: 11pt;
    }
  
    header #serviciosInfoSm a {
      margin-top: 50px;
      display: block;
      font-size: 1rem;
      padding: 0.7rem 2rem;
      cursor: pointer;
      font-weight: 900;
      color: var(--s-dark-text);
      background-color: var(--primary);
      text-decoration: none;
    }

    header #serviciosInfoSm .news-title {
      color: var(--primary);
      font-size: 0.95rem;
      position: relative;
      top: -447px;
      left: 68px;
    }
  
    /* --- --- --- --- --- --- --- --- ---- --- --- --- --- --- --- --- --- */
  
    /*------------------------------------------------------------------------------*/
    #nosotros {
      background-color: white;
      color: var(--dark-text);
      width: 100%;
    }
  
    #nosotros .image-bg {
      width: 100%;
      height: 30vh;
      background-image: url("../../images/inicio/displayKS.png");
      background-size: cover;
      background-attachment: fixed;
      background-repeat: no-repeat;
      background-position: center;
      filter: grayscale(60%);
    }
  
    #nosotros article {
      width: calc(100% - 30px);
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      text-align: justify;
      padding: 15px;
      padding-top: 40px;
      padding-bottom: 40px;
    }
  
    #nosotros article h2 {
      padding-bottom: 30px;
      font-size: 2rem;
    }
  
    #nosotros article div i {
      font-size: 1.8rem;
      margin-top: 10px;
    }
  
    #nosotros article div .bx {
      font-size: 2rem;
    }
    /*------------------------------------------------------------------------------*/
  
    /*------------------------------------------------------------------------------*/
    #productos {
      background-color: black;
      color: white;
      overflow: hidden;
    }
  
    #productos .info-card {
      height: 100px;
      overflow: hidden;
      transition: height 0.4s;
    }
  
    #productos .info-card div {
      display: none;
    }
  
    #productos .info-card div p {
      padding-top: 15px;
    }
  
    #productos .info-card div h2 {
      font-size: 1.5rem;
    }
  
    #productos .info-card h1 {
      display: block;
      font-size: 1.4rem;
      width: 100%;
      text-align: left;
      position: relative;
      top: -280%;
      padding-left: 15px;
    }
  
    #productos .info-card .image-bg {
      height: 320px !important;
      width: 100vw;
      position: relative;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      filter: brightness(20%) blur(1px);
    }
  
    /*#productos #mod-combate .image-bg {
      background-image: url("../../images/inicio/acc1.jpg");
    }
  
    #productos #mod-team .image-bg {
      background-image: url("../../images/inicio/acc2.jpg");
    }
  
    #productos #mod-poomsae .image-bg {
      background-image: url("../../images/inicio/acc3.jpeg");
    }
  
    #productos #mod-circuito .image-bg {
      background-image: url("../../images/inicio/acc4.jpg");
    }*/
  
    #productos .active {
      height: 320px;
      overflow: hidden;
    }
  
    #productos .active h1 {
      display: none;
    }
  
    #productos .active div {
      display: block;
      position: relative;
      top: -50%;
      padding: 15px;
      padding-top: 0;
    }
  
    #productos .active .image-bg {
      filter: brightness(35%) blur(0px);
    }
    /*------------------------------------------------------------------------------*/
  
    /*------------------------------------------------------------------------------*/
    #precios {
      background-color: #212121;
      color: white;
      height: unset;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }
  
    .e-card {
      background: #2F383E;
      box-shadow: 0px 8px 28px -9px rgba(0,0,0,0.45);
      position: relative;
      width: calc(300px + 20vh);
      max-width: calc(100% - 30px);
      height: 800px;
      margin: 30px;
      border-radius: 16px;
      overflow: hidden;
      overflow-y: auto;
    }
  
    .wave {
      position: absolute;
      width: 540px;
      height: 700px;
      opacity: 0.6;
      left: 0;
      top: 0;
      margin-left: -50%;
      margin-top: -70%;
      opacity: 0.3;
    }
  
    #precios #precios-w1 .wave {
      background: linear-gradient(744deg,#FF4040,#FF0000 60%,#FFAE00);
    }
  
    #precios #precios-w2 .wave {
      background: linear-gradient(744deg,#40FFBA,#42F3DE 60%,#04BF72);
    }
  
    #precios #precios-w3 .wave {
      background: linear-gradient(744deg,#40E2FF,#42BDF3 60%,#0451BF);
    }
  
    .infotop {
      text-align: center;
      font-size: 18pt;
      position: absolute;
      top: 1.4em;
      left: 0;
      right: 0;
      color: rgb(255, 255, 255);
      font-weight: 600;
    }
  
    .infotop i {
      font-size: 24pt;
    }
  
    .name {
      font-size: 12pt;
      font-weight: 100;
      position: relative;
      padding: 1em;
      text-align: justify;
    }
  
    .name b {
      float: right;
      font-size: 18pt;
    }
  
    .name t {
      font-weight: 700;
      font-size: 10pt;
    }
  
    .name d {
      font-weight: 800;
      font-size:16pt;
    }
  
    .wave:nth-child(2),
    .wave:nth-child(3) {
      top: 210px;
    }
  
    .playing .wave {
      border-radius: 40%;
    }
  
    .wave {
      border-radius: 40%;
    }
  
    .playing .wave:nth-child(2) {
      animation-duration: 32s;
    }
  
    .wave:nth-child(2) {
      animation-duration: 400s;
    }
  
    .playing .wave:nth-child(3) {
      animation-duration: 40s;
    }
  
    .wave:nth-child(3) {
      animation-duration: 360s;
    }
    /*------------------------------------------------------------------------------*/
  
    /*------------------------------------------------------------------------------*/
    #torneo {
      background-color: #27292F;
      color: white;
      height: auto;
    }
  
    #torneo img {
      position: relative;
      left: 50%;
      transform: translate(-50%, 0%);
      width: 125%;
      filter: grayscale(85%);
    }
  
    #torneo #trophy {
      margin-top: -36vw;
      position: relative;
      font-size: 60vw;
      width: 100%;
      text-align: center;
      color: #FFD8A2;
      top: calc(44vw);
    }
  
    #torneo .firework {
      display: none;
    }
  
    #torneo .detail {
      width: calc(100% - 30px);
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      padding: 15px;
      padding-bottom: 50px;
    }
  
    #torneo .detail * {
      width: 100%;
    }
  
    #torneo .detail h1 {
      font-size: 50pt;
    }
  
    #torneo .detail h3 {
      font-size: 17pt;
    }
  
    #torneo .detail h3 i {
      display: inline;
      margin-right: 8px;
    }
  
    #torneo .detail p {
      text-align: justify;
    }
  
    #torneo .detail a {
      text-align: center;
      text-decoration: none;
    }
  
    #torneo .detail .opendoc {
      width: unset;
      background-color: #415185;
      padding: 12px;
      border-radius: 3px;
      cursor: pointer;
    }
  
    #torneo .detail .opendoc * {
      display: inline;
    }
  
    #torneo .detail .opendoc label {
      font-size: 12pt;
      color: white;
      margin-left: 10px;
      cursor: pointer;
    }
  
    #torneo .detail .opendoc i {
      font-size: 18pt;
      color: white;
      position: relative;
      top: 3px;
    }
    /*------------------------------------------------------------------------------*/
  
    /*------------------------------------------------------------------------------*/
    #contacto {
      background-color: #212121;
      color: white;
      text-shadow: gray 1px 0 8px;
    }
  
    #contacto h1 {
      text-align: center;
      padding-top: 30px;
      padding-bottom: 25px;
    }
  
    #contacto #contacto-container-cards {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }
  
    #contacto #contacto-container-cards .contact-card {
      margin: 0px;
      margin-bottom: 30px;
      background-color: #2F383E;
      font-size: 17px;
      width: 250px;
      height: 250px;
      text-align: center;
      border-radius: 16px;
      overflow: hidden;
    }
  
    #contacto #contacto-container-cards .contact-card i {
      font-size: 36pt;
      margin-bottom: 5px;
      transition: margin-top 0.5s;
    }
  
    #contacto #contacto-container-cards .contact-card p {
      cursor: default;
    }
  
    #contacto #contacto-container-cards .contact-card #wa {
      background: linear-gradient(57deg, #45AFC4, #4BBED5, #5CF581, #4EDE71);
      background-size: 250% 250%;
    }
  
    #contacto #contacto-container-cards .contact-card #ce {
      background: linear-gradient(57deg, #CA4A4A, #EE5252, #F5AD5C, #FEB25E);
      background-size: 250% 250%;
    }
  
    #contacto #contacto-container-cards .contact-card .top-card {
      height: 250px;
      width: 250px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      position: relative;
      top: 0;
      transition: top 0.5s ease 0s;
    }
  
    #contacto #contacto-container-cards .contact-card:hover .top-card {
      top: -120px;
    }
  
    #contacto #contacto-container-cards .contact-card:hover a {
      opacity: 1;
      transition: opacity 0.7s ease-in-out;
    }
  
    #contacto #contacto-container-cards .contact-card:hover i {
      margin-top: 120px;
    }
  
    #contacto #contacto-container-cards .contact-card a {
      position: relative;
      top: -90px;
      text-decoration: none;
      color: white;
      text-shadow: none;
      opacity: 0;
      transition: opacity 0.3s;
    }
    /*-------------------------------------------------------------------------------*/
  
    /*------------------------------------------------------------------------------*/
    footer {
      background-color: #2F383E;
      height: 160px;
      position: relative;
      width: 100%;
      text-shadow: none;
      color: #7D8F9C;
    }
  
    footer div {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 50px;
      padding-bottom: 25px;
    }
  
    footer div a {
      text-decoration: none;
      color: #7D8F9C;
    }
  
    footer div i {
      margin: 0px 20px;
      font-size: 22px;
      cursor: pointer;
      transition: color 0.3s;
    }
  
    footer p {
      text-align: center;
      font-size: 10pt;
    }
    /*------------------------------------------------------------------------------*/
  
    /* -- Up scroll button -- */
    main .up-scroll-btn {
      font-size: 4rem;
      color: #FEB25E;
      z-index: 10;
      position: absolute;
      bottom: 2rem;
      right: 2rem;
    }
  }
}

/* ___________________________________________________________________________________________________________________ */
/* |_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_| */
/* |_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_| */
/* |_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_|_| */

@media (min-width: 1336px) {
  .styleInicio {
    background-image: none;
    background-size: cover;
    background-attachment: fixed;
    background-position: center;
    height: 100vh;
    overflow-x: hidden;
    overflow-y: scroll;
    scroll-snap-type: y mandatory;
    scroll-behavior: smooth;
    
    /* ScrollBar */

    ::-webkit-scrollbar-thumb {
      border-radius: 0px;
    }

    ::-webkit-scrollbar {
      width: 9px;
      height: 9px;
    }

    ::-webkit-scrollbar-track {
      background: transparent; 
    }

    ::-webkit-scrollbar-thumb {
      background: #576974;
    }

    ::-webkit-scrollbar-thumb:hover {
      background: var(--dark-text); 
    }

    #minimal-menu {
      display: none;
    }

    /* --- --- --- --- --- --- --- --- Home --- --- --- --- --- --- --- --- */

    header {
      height: 100vh;
      background-color: rgba(0, 0, 0, 0.733);
      display: flex;
      align-items: center;
    }

    header #marcadorInfoSm {
      color: white;
      text-align: center;
      width: 33.3%;
      display: flex;
      align-items: center;
      flex-direction: column;
      --animate-duration: 1.1s;
    }

    header #marcadorInfoSm h1 {
      font-size: 4rem;
      padding-bottom: 0.5rem;
    }

    header #marcadorInfoSm h1 p {
      position: absolute;
      font-size: 22px;
      margin-top: -24px;
      margin-left: 227px;
    }

    header #marcadorInfoSm h3 {
      font-size: 1.2rem;
      font-weight: 700;
      padding-top: 50px;
    }

    header #marcadorInfoSm h3 b {
      font-weight: 400;
      font-size: 12pt;
    }

    header #marcadorInfoSm a {
      margin-top: 1.2rem;
      font-size: 1rem;
      padding: 0.7rem 1.3rem;
      cursor: pointer;
      font-weight: 900;
      color: var(--s-dark-text);
      background-color: var(--primary);
      text-decoration: none;
    }

    /* +-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+ */

    header #torneoInfoSm {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: white;
      width: 33.3%;
      --animate-duration: 1.1s;
    }

    #torneoInfoSm * {
      z-index: 2;
    }

    #torneoInfoSm i {
      position: absolute;
      color: #ffffff46;
      font-size: 12.5rem;
      z-index: 1;
      margin-top: 70px;
    }

    #torneoInfoSm h1 {
      font-size: 3rem;
      padding-bottom: 1.5rem;
    }

    #torneoInfoSm h1 p {
      position: absolute;
      font-size: 17px;
      margin-top: -20px;
      margin-left: 186px;
    }

    #torneoInfoSm h3 {
      font-size: 1.3rem;
      font-weight: 700;
      padding-bottom: 20px;
    }

    #torneoInfoSm h4 {
      font-size: 1rem;
      margin-top: 25px;
      margin-bottom: 7px;
    }

    #torneoInfoSm #timeCardsContainer {
      display: flex;
    }

    #torneoInfoSm #timeCardsContainer div {
      padding: 15px;
      padding-top: 0px;
      text-align: center;
    }

    #torneoInfoSm #timeCardsContainer div h1 {
      font-size: 2.7rem;
      width: 80px !important;
      background: rgba(0, 0, 0, 0.5);
      padding-bottom: unset;
    }

    #torneoInfoSm #timeCardsContainer div h5 {
      font-size: 0.6rem;
      background: rgba(0, 0, 0, 0.5);
      padding-bottom: 8px;
    }

    #torneoInfoSm a {
      margin-top: 50px;
      display: block;
      font-size: 1rem;
      padding: 0.7rem 1.3rem;
      cursor: pointer;
      font-weight: 900;
      color: var(--s-dark-text);
      background-color: var(--primary);
      text-decoration: none;
      margin-bottom: 15px;
    }

    /* +-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+ */

    header #serviciosInfoSm {
      color: white;
      text-align: center;
      width: 33.3%;
      display: flex;
      align-items: center;
      flex-direction: column;
      --animate-duration: 1.1s;
    }

    header #serviciosInfoSm h1 {
      font-size: 3rem;
      padding-bottom: 0.5rem;
    }

    header #serviciosInfoSm h1 p {
      position: absolute;
      font-size: 17px;
      margin-top: -20px;
      margin-left: 177px;
    }

    header #serviciosInfoSm div {
      width: 460px;
      display: flex;
    }

    header #serviciosInfoSm div i {
      padding-top: 25px;
      font-size: 47px;
      width: 77px;
      padding-left: 10px;
      padding-right: 20px;
    }

    header #serviciosInfoSm div h3 {
      font-size: 1rem;
      font-weight: 700;
      padding-top: 25px;
      text-align: left;
    }

    header #serviciosInfoSm div h3 b {
      font-weight: 400;
      font-size: 11pt;
    }

    header #serviciosInfoSm a {
      margin-top: 50px;
      display: block;
      font-size: 1rem;
      padding: 0.7rem 1.3rem;
      cursor: pointer;
      font-weight: 900;
      color: var(--s-dark-text);
      background-color: var(--primary);
      text-decoration: none;
    }

    header #serviciosInfoSm .news-title {
      color: var(--primary);
      font-size: 0.95rem;
      position: relative;
      top: -409px;
      left: 68px;
    }

    /* --- --- --- --- --- --- --- --- ---- --- --- --- --- --- --- --- --- */

    /*------------------------------------------------------------------------------*/
    /*----------------------------------- NAV --------------------------------------*/
    /*------------------------------------------------------------------------------*/
    nav {
      background-color: rgba(255, 255, 255, 0.035);
      height: 120px;
      position: fixed;
      display: block;
      width: 100vw;
      top: 0;
      z-index: 2;
      transition: background-color 0.3s;
    }

    nav img {
      width: 110px;
    }

    nav ul {
      display: flex;
      justify-content: center;
      align-items: center;
      list-style-type: none;
      height: 100%;
    }

    nav ul a {
      color: white;
      padding: 0 5vw;
      text-decoration: none;
      font-size: 1.1rem;
    }

    nav li {
      transition: transform 0.3s;
    }

    nav li:hover {
      transform: scale(1.2);
    }

    nav a:hover {
      font-weight: 700;
    }

    .stc {
      transform: scale(1) !important;
    }
    /*------------------------------------------------------------------------------*/
    /*------------------------------------------------------------------------------*/
    /*------------------------------------------------------------------------------*/

    .section {
      height: 100vh;
      scroll-snap-align: start;
      overflow: hidden;
    }

    /*------------------------------------------------------------------------------*/
    #nosotros {
      background-color: white;
      color: var(--dark-text);
      height: calc(100vh - 120px);
      padding-top: 120px;
    }

    #nosotros .image-bg {
      float: left;
      width: 40%;
      height: 100vh;
      margin-top: -120px;
      background-image: url("../../images/inicio/displayKS.png");
      background-size: auto 100%;
      background-attachment: fixed;
      background-repeat: no-repeat;
      filter: grayscale(60%);
    }

    #nosotros article {
      width: calc(60% - 40px);
      height: 100%;
      float: left;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      text-align: justify;
      padding: 20px;
    }

    #nosotros article h2 {
      padding-bottom: 30px;
      font-size: 2rem;
    }

    #nosotros article div i {
      font-size: 1.8rem;
      margin-top: 10px;
    }

    #nosotros article div .bx {
      font-size: 2rem;
    }
    /*------------------------------------------------------------------------------*/

    /*------------------------------------------------------------------------------*/
    #productos {
      background-color: black;
      clear: both;
      height: calc(100vh - 120px);
      padding-top: 120px;
    }

    #productos .info-card {
      width: calc(10% - 40px);
      float: left;
      background-color: black;
      height: 100%;
      padding: 20px;
      cursor: pointer;
      transition: width 0.5s;
      z-index: 1;
    }

    #productos .info-card div {
      position: absolute;
      height: 280px;
      top: calc(300vh - 280px);
      color: white;
      width: calc(70% - 40px);
      padding-right: 20px;
      display: none;
    }

    #productos .info-card div p {
      padding-top: 15px;
    }

    #productos .info-card div h2 {
      font-size: 2rem;
    }

    #productos .info-card h1 {
      position: relative;
      transform: rotate(-90deg);
      font-size: 19.5pt;
      width: 440px;
      top: calc(-290px - 40%);
      color: white;
      left: calc(-130%);
      cursor: pointer;
    }

    #productos .info-card .image-bg {
      width: calc(100% + 40px);
      height: calc(100% + 40%);
      position: relative;
      top: -20px;
      left: -20px;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      filter: brightness(15%) blur(1px);
    }

    /*#productos #mod-combate .image-bg {
      background-image: url("../../images/inicio/acc1.jpg");
    }

    #productos #mod-team .image-bg {
      background-image: url("../../images/inicio/acc2.jpg");
    }

    #productos #mod-poomsae .image-bg {
      background-image: url("../../images/inicio/acc3.jpeg");
    }

    #productos #mod-circuito .image-bg {
      background-image: url("../../images/inicio/acc4.jpg");
    }*/

    #productos .active {
      width: calc(70% - 40px);
      cursor: auto;
    }

    #productos .active h1 {
      display: none;
    }

    #productos .active div {
      display: block;
    }

    #productos .active .image-bg {
      filter: brightness(35%) blur(0px);
    }
    /*------------------------------------------------------------------------------*/

    /*------------------------------------------------------------------------------*/
    #precios {
      background-color: #212121;
      color: white;
      height: calc(100vh - 120px);
      padding-top: 120px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .e-card {
      background: #2F383E;
      box-shadow: 0px 8px 28px -9px rgba(0,0,0,0.45);
      position: relative;
      width: calc(33% - 60px);
      height: calc(100% - 60px);
      margin: 30px;
      border-radius: 16px;
      overflow: hidden;
      overflow-y: auto;
    }

    .wave {
      position: absolute;
      width: 540px;
      height: 700px;
      opacity: 0.6;
      left: 0;
      top: 0;
      margin-left: -50%;
      margin-top: -70%;
      opacity: 0.3;
    }

    #precios #precios-w1 .wave {
      background: linear-gradient(744deg,#FF4040,#FF0000 60%,#FFAE00);
    }

    #precios #precios-w2 .wave {
      background: linear-gradient(744deg,#40FFBA,#42F3DE 60%,#04BF72);
    }

    #precios #precios-w3 .wave {
      background: linear-gradient(744deg,#40E2FF,#42BDF3 60%,#0451BF);
    }

    .infotop {
      text-align: center;
      font-size: 18pt;
      position: absolute;
      top: 1.4em;
      left: 0;
      right: 0;
      color: rgb(255, 255, 255);
      font-weight: 600;
    }

    .infotop i {
      font-size: 24pt;
    }

    .name {
      font-size: 12pt;
      font-weight: 100;
      position: relative;
      padding: 1em;
      text-align: justify;
    }

    .name b {
      float: right;
      font-size: 18pt;
    }

    .name t {
      font-weight: 700;
      font-size: 10pt;
    }

    .name d {
      font-weight: 800;
      font-size:16pt;
    }

    .wave:nth-child(2),
    .wave:nth-child(3) {
      top: 210px;
    }

    .playing .wave {
      border-radius: 40%;
      animation: wave 24s infinite linear;
    }

    .wave {
      border-radius: 40%;
      animation: wave 440s infinite linear;
    }

    .playing .wave:nth-child(2) {
      animation-duration: 32s;
    }

    .wave:nth-child(2) {
      animation-duration: 400s;
    }

    .playing .wave:nth-child(3) {
      animation-duration: 40s;
    }

    .wave:nth-child(3) {
      animation-duration: 360s;
    }
    /*------------------------------------------------------------------------------*/

    /*------------------------------------------------------------------------------*/
    #contacto {
      background-color: #212121;
      color: white;
      height: calc(100vh - 120px);
      padding-top: 120px;
      text-shadow: gray 1px 0 8px;
    }

    #contacto h1 {
      text-align: center;
      padding-top: 50px;
      padding-bottom: 50px;
    }

    #contacto #contacto-container-cards {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    #contacto #contacto-container-cards .contact-card {
      margin: 40px;
      background-color: #2F383E;
      font-size: 17px;
      width: 250px;
      height: 250px;
      text-align: center;
      border-radius: 16px;
      overflow: hidden;
    }

    #contacto #contacto-container-cards .contact-card i {
      font-size: 36pt;
      margin-bottom: 5px;
      transition: margin-top 0.5s;
    }

    #contacto #contacto-container-cards .contact-card p {
      cursor: default;
    }

    #contacto #contacto-container-cards .contact-card #wa {
      background: linear-gradient(57deg, #45AFC4, #4BBED5, #5CF581, #4EDE71);
      animation: gradient 2s ease infinite;
      background-size: 250% 250%;
    }

    #contacto #contacto-container-cards .contact-card #ce {
      background: linear-gradient(57deg, #CA4A4A, #EE5252, #F5AD5C, #FEB25E);
      animation: gradient 2s ease infinite;
      background-size: 250% 250%;
    }

    #contacto #contacto-container-cards .contact-card .top-card {
      height: 250px;
      width: 250px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      position: relative;
      top: 0;
      transition: top 0.5s ease 0s;
    }

    #contacto #contacto-container-cards .contact-card:hover .top-card {
      top: -120px;
    }

    #contacto #contacto-container-cards .contact-card:hover a {
      opacity: 1;
      transition: opacity 0.7s ease-in-out;
    }

    #contacto #contacto-container-cards .contact-card:hover i {
      margin-top: 120px;
    }

    #contacto #contacto-container-cards .contact-card a {
      position: relative;
      top: -90px;
      text-decoration: none;
      color: white;
      text-shadow: none;
      opacity: 0;
      transition: opacity 0.3s;
    }
    /*-------------------------------------------------------------------------------*/

    /*------------------------------------------------------------------------------*/
    #torneo {
      background-color: #27292F;
      color: white;
      height: calc(100vh - 120px);
      padding-top: 120px;
    }

    #torneo img {
      position: relative;
      left: 50%;
      top: calc(100vh - 470px);
      filter: grayscale(85%);
    }

    #torneo #trophy {
      position: absolute;
      bottom: -400vh !important;
      left: 65%;
      font-size: 330pt;
      color: #FFD8A2;
    }

    #torneo .firework {
      position: absolute;
      font-size: 80pt;
      animation: firework 4s linear infinite;
      transform: scale(0);
    }

    #torneo #firework-1 {
      top: calc(400vh + 140px);
      left: 50%;
      animation-delay: 4s;
      filter: saturate(0.2);
    }

    #torneo #firework-2 {
      top: calc(400vh + 80px);
      left: 66%;
      width: 500px;
      filter: saturate(1);
    }

    #torneo #firework-3 {
      top: calc(400vh + 80px);
      left: 80%;
      animation-delay: 3s;
      filter: saturate(0.5);
    }

    #torneo .detail {
      height: calc(100vh - 120px);
      padding-top: 120px;
      position: absolute;
      top: 400vh;
      width: calc(50vw - 200px);
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      padding: 100px;
    }

    #torneo .detail * {
      width: 100%;
    }

    #torneo .detail h1 {
      font-size: 50pt;
    }

    #torneo .detail h3 {
      font-size: 17pt;
    }

    #torneo .detail h3 i {
      display: inline;
      margin-right: 8px;
    }

    #torneo .detail p {
      text-align: justify;
    }

    #torneo .detail a {
      text-align: center;
      text-decoration: none;
      width: unset;
      margin-left: auto;
    }

    #torneo .detail .opendoc {
      width: unset;
      background-color: #415185;
      padding: 12px;
      border-radius: 3px;
      cursor: pointer;
    }

    #torneo .detail .opendoc * {
      display: inline;
    }

    #torneo .detail .opendoc label {
      font-size: 12pt;
      color: white;
      margin-left: 10px;
      cursor: pointer;
    }

    #torneo .detail .opendoc i {
      font-size: 18pt;
      color: white;
      position: relative;
      top: 3px;
    }
    /*------------------------------------------------------------------------------*/

    /*------------------------------------------------------------------------------*/
    footer {
      background-color: #2F383E;
      height: 160px;
      position: absolute;
      top: calc(600vh - 160px);
      width: 100%;
      text-shadow: none;
      color: #7D8F9C;
    }

    footer div {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 50px;
      padding-bottom: 25px;
    }

    footer div a {
      text-decoration: none;
      color: #7D8F9C;
    }

    footer div i {
      margin: 0px 20px;
      font-size: 22px;
      cursor: pointer;
      transition: color 0.3s;
    }

    footer div i:hover {
      color: #DAE8F4;
    }

    footer p {
      text-align: center;
      font-size: 10pt;
    }
    /*------------------------------------------------------------------------------*/
  }
}

/* -- Animation Delay's -- */
.anim-delay-100ms { animation-delay: 0.1s; } .anim-delay-1s-200ms { animation-delay: 1.2s; }
.anim-delay-200ms { animation-delay: 0.2s; } .anim-delay-1s-300ms { animation-delay: 1.3s; }
.anim-delay-300ms { animation-delay: 0.3s; } .anim-delay-1s-400ms { animation-delay: 1.4s; }
.anim-delay-400ms { animation-delay: 0.4s; } .anim-delay-1s-500ms { animation-delay: 1.5s; }
.anim-delay-500ms { animation-delay: 0.5s; } .anim-delay-1s-600ms { animation-delay: 1.6s; }
.anim-delay-600ms { animation-delay: 0.6s; } .anim-delay-1s-700ms { animation-delay: 1.7s; }
.anim-delay-700ms { animation-delay: 0.7s; } .anim-delay-1s-800ms { animation-delay: 1.8s; }
.anim-delay-800ms { animation-delay: 0.8s; } .anim-delay-1s-900ms { animation-delay: 1.9s; }
.anim-delay-900ms { animation-delay: 0.9s; } .anim-delay-2s { animation-delay: 2s; }
.anim-delay-1s { animation-delay: 1s; } .anim-delay-2s-100ms { animation-delay: 2.1s; }
.anim-delay-1s-100ms { animation-delay: 1.1s; } .anim-delay-2s-200ms { animation-delay: 2.2s; }
.anim-delay-2s-300ms { animation-delay: 2.3s; } .anim-delay-3s-300ms { animation-delay: 3.3s; }
.anim-delay-2s-400ms { animation-delay: 2.4s; } .anim-delay-3s-400ms { animation-delay: 3.4s; }
.anim-delay-2s-500ms { animation-delay: 2.5s; } .anim-delay-3s-500ms { animation-delay: 3.5s; }
.anim-delay-2s-600ms { animation-delay: 2.6s; } .anim-delay-3s-600ms { animation-delay: 3.6s; }
.anim-delay-2s-700ms { animation-delay: 2.7s; } .anim-delay-3s-700ms { animation-delay: 3.7s; }
.anim-delay-2s-800ms { animation-delay: 2.8s; } .anim-delay-3s-800ms { animation-delay: 3.8s; }
.anim-delay-2s-900ms { animation-delay: 2.9s; } .anim-delay-3s-900ms { animation-delay: 3.9s; }
.anim-delay-3s { animation-delay: 3s; } .anim-delay-4s { animation-delay: 4s; }
.anim-delay-3s-100ms { animation-delay: 3.1s; } .anim-delay-4s-100ms { animation-delay: 4.1s; }
.anim-delay-3s-200ms { animation-delay: 3.2s; } .anim-delay-4s-200ms { animation-delay: 4.2s; }
.anim-delay-4s-300ms { animation-delay: 4.3s; } .anim-delay-5s-300ms { animation-delay: 5.3s; }
.anim-delay-4s-400ms { animation-delay: 4.4s; } .anim-delay-5s-400ms { animation-delay: 5.4s; }
.anim-delay-4s-500ms { animation-delay: 4.5s; } .anim-delay-5s-500ms { animation-delay: 5.5s; }
.anim-delay-4s-600ms { animation-delay: 4.6s; } .anim-delay-5s-600ms { animation-delay: 5.6s; }
.anim-delay-4s-700ms { animation-delay: 4.7s; } .anim-delay-5s-700ms { animation-delay: 5.7s; }
.anim-delay-4s-800ms { animation-delay: 4.8s; } .anim-delay-5s-800ms { animation-delay: 5.8s; }
.anim-delay-4s-900ms { animation-delay: 4.9s; } .anim-delay-5s-900ms { animation-delay: 5.9s; }
.anim-delay-5s { animation-delay: 5s; } .anim-delay-6s { animation-delay: 6s; }
.anim-delay-5s-100ms { animation-delay: 5.1s; } .anim-delay-6s-100ms { animation-delay: 6.1s; }
.anim-delay-5s-200ms { animation-delay: 5.2s; } .anim-delay-6s-200ms { animation-delay: 6.2s; }
.anim-delay-6s-300ms { animation-delay: 6.3s; } .anim-delay-7s-300ms { animation-delay: 7.3s; }
.anim-delay-6s-400ms { animation-delay: 6.4s; } .anim-delay-7s-400ms { animation-delay: 7.4s; }
.anim-delay-6s-500ms { animation-delay: 6.5s; } .anim-delay-7s-500ms { animation-delay: 7.5s; }
.anim-delay-6s-600ms { animation-delay: 6.6s; } .anim-delay-7s-600ms { animation-delay: 7.6s; }
.anim-delay-6s-700ms { animation-delay: 6.7s; } .anim-delay-7s-700ms { animation-delay: 7.7s; }
.anim-delay-6s-800ms { animation-delay: 6.8s; } .anim-delay-7s-800ms { animation-delay: 7.8s; }
.anim-delay-6s-900ms { animation-delay: 6.9s; } .anim-delay-7s-900ms { animation-delay: 7.9s; }
.anim-delay-7s { animation-delay: 7s; } .anim-delay-8s { animation-delay: 8s; }
.anim-delay-7s-100ms { animation-delay: 7.1s; } .anim-delay-8s-100ms { animation-delay: 8.1s; }
.anim-delay-7s-200ms { animation-delay: 7.2s; } .anim-delay-8s-200ms { animation-delay: 8.2s; }
.anim-delay-9s-300ms { animation-delay: 9.3s; } .anim-delay-10s-300ms { animation-delay: 10.3s; }
.anim-delay-9s-400ms { animation-delay: 9.4s; } .anim-delay-10s-400ms { animation-delay: 10.4s; }
.anim-delay-9s-500ms { animation-delay: 9.5s; } .anim-delay-10s-500ms { animation-delay: 10.5s; }
.anim-delay-9s-600ms { animation-delay: 9.6s; } .anim-delay-10s-600ms { animation-delay: 10.6s; }
.anim-delay-9s-700ms { animation-delay: 9.7s; } .anim-delay-10s-700ms { animation-delay: 10.7s; }
.anim-delay-9s-800ms { animation-delay: 9.8s; } .anim-delay-10s-800ms { animation-delay: 10.8s; }
.anim-delay-9s-900ms { animation-delay: 9.9s; } .anim-delay-10s-900ms { animation-delay: 10.9s; }
.anim-delay-10s { animation-delay: 10s; }
.anim-delay-10s-100ms { animation-delay: 10.1s; }
.anim-delay-10s-200ms { animation-delay: 10.2s; }