.styleLoginClientes {
    input:focus {
        outline-color: rgb(255, 224, 145);
        outline-style: solid;
        outline-width: 2px;
    }

    button:hover {
        background-color: rgb(45, 54, 59) !important;
        cursor: pointer;
    }

    /* --- DESKTOP --- */
    @media (min-width: 900px) {
        #main {
            width: 100vw;
            height: 100vh;
            background-image: url("../../images/clientes/fondoLogin.jpg");
            background-size: cover;
            background-attachment: fixed;
            background-repeat: no-repeat;
            background-position: center;
            overflow: hidden;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        #form {
            width: 700px;
            height: 400px;
            background-color: white;
            box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            #formContainerTop {
                position: absolute;
                width: 180px;
                height: 100px;
                display: flex !important;
                justify-content: center !important;
                align-items: center !important;
                top: 0;
                left: 0;
                margin-top: -20px;
                margin-left: -60px;

                .triangle {
                    width: 180px;
                    height: 100px;
                    background-color: rgb(45, 54, 59);
                    clip-path: polygon(69% 0, 0 100%, 100% 100%);
                    position: absolute;
                }

                #tbg-1 {
                    transform: rotate(20deg) translateY(-20px);
                    background-color: rgb(241, 230, 202);
                }

                #tbg-2 {
                    transform: rotate(330deg) translate(-20px, -20px);
                }

                img {
                    width: 55px;
                    position: relative;
                    z-index: 2;
                }
            }

            h1 {
                font-size: 20pt;
                margin-bottom: 0px;
                text-align: left;
                width: 50%;
                color: rgb(23, 24, 27);
            }

            p {
                font-size: 10pt;
                margin-bottom: 35px;
                text-align: left;
                width: 50%;
                color: rgb(23, 24, 27);
            }

            form {
                width: 50%;
            }

            input {
                width: calc(100% - 40px);
                display: block;
                margin-bottom: 30px;
                border: none;
                font-size: 11pt;
                padding: 12px 20px;
                background-color: rgb(240, 243, 244);
            }

            button {
                float: right;
                padding: 12px 35px;
                font-size: 8pt;
                border: none;
                background-color: rgb(91, 109, 119);
                color: white;
                border-radius: 4px;
            }

            #formContainerBottom {
                position: absolute;
                width: 180px;
                height: 100px;
                display: flex;
                justify-content: center;
                align-items: center;
                bottom: 0;
                right: 0;
                margin-bottom: -20px;
                margin-right: -60px;

                .triangle {
                    width: 180px;
                    height: 100px;
                    background-color: rgb(45, 54, 59);
                    clip-path: polygon(69% 0, 0 100%, 100% 100%);
                    position: absolute;
                }

                #tbg-3 {
                    transform: rotate(80deg) translateY(-20px);
                    background-color: rgb(241, 230, 202);
                }

                #tbg-4 {
                    transform: rotate(160deg) translate(-20px, -20px);
                }
            }
        }

        .footerInfo {
            position: absolute;
            bottom: 0;
            right: 0;
            font-size:10pt;
            margin-right: 20px;
            margin-bottom: 20px;
            color: rgb(88, 91, 104);
        }
    }

    /* --- MOBILE --- */
    @media (max-width: 900px) {
        #main {
            width: 100vw;
            min-height: 100vh;
            background-image: url("../../images/clientes/fondoLogin.jpg");
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
            overflow: hidden;
        }

        #form {
            width: 95%;
            margin: auto;
            padding-top: 100px;
            padding-bottom: 130px;
            background-color: white;
            box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin-top: 100px;
            margin-bottom: 100px;
    
            #formContainerTop {
                position: absolute;
                width: 180px;
                height: 100px;
                display: flex;
                justify-content: center;
                align-items: center;
                top: 0;
                margin-top: -40px;
                left: 0;
                margin-left: -37px;
    
                .triangle {
                    width: 180px;
                    height: 100px;
                    background-color: rgb(45, 54, 59);
                    clip-path: polygon(69% 0, 0 100%, 100% 100%);
                    position: absolute;
                }
    
                #tbg-1 {
                    transform: rotate(20deg) translateY(-20px);
                    background-color: rgb(241, 230, 202);
                }
    
                #tbg-2 {
                    transform: rotate(330deg) translate(-20px, -20px);
                }
    
                img {
                    width: 55px;
                    position: relative;
                    z-index: 2;
                }
            }
    
            h1 {
                font-size: 20pt;
                margin-bottom: 0px;
                text-align: left;
                width: 92%;
                color: rgb(23, 24, 27);
            }
    
            p {
                font-size: 10pt;
                margin-bottom: 35px;
                text-align: left;
                width: 92%;
                color: rgb(23, 24, 27);
            }
    
            form {
                width: 92%;
            }
    
            input {
                width: calc(100% - 40px);
                display: block;
                margin-bottom: 30px;
                border: none;
                font-size: 11pt;
                padding: 12px 20px;
                background-color: rgb(240, 243, 244);
            }
    
            button {
                float: right;
                padding: 12px 35px;
                font-size: 8pt;
                border: none;
                background-color: rgb(91, 109, 119);
                color: white;
                border-radius: 4px;
            }
    
            #formContainerBottom {
                position: absolute;
                width: 180px;
                height: 100px;
                display: flex;
                justify-content: center;
                align-items: center;
                bottom: 0;
                right: 0;
                margin-bottom: -20px;
                margin-right: -60px;
    
                .triangle {
                    width: 180px;
                    height: 100px;
                    background-color: rgb(45, 54, 59);
                    clip-path: polygon(69% 0, 0 100%, 100% 100%);
                    position: absolute;
                }
    
                #tbg-3 {
                    transform: rotate(80deg) translateY(-20px);
                    background-color: rgb(241, 230, 202);
                }
    
                #tbg-4 {
                    transform: rotate(160deg) translate(-20px, -20px);
                }
            }
        }
    
        .footerInfo {
            position: relative;
            bottom: 0;
            font-size: 9pt;
            width: 100%;
            text-align: center;
            padding: 11px 0;
            color: whitesmoke;
            background-color: rgb(91, 109, 119);
        }
    }
}