.stylePrimeraCopa {

    width: 100vw;
    overflow: hidden;
    background-color: white;
    overflow-y: auto;

    body {
        width: 100vw;
        overflow: hidden;
    }

    nav {
        position: relative;
        z-index: 2;
        height: calc(112.5px + 7.5vw);
        overflow: hidden;
    }

    nav #nav-bg #a {
        width:  calc(150px + 10vw);
        height: calc(112.5px + 7.5vw);
        clip-path: polygon(0 0, 100% 0%, 45% 100%, 0 79%);
        background-color: rgb(45, 54, 59);
    }

    nav #nav-bg #b {
        width:  calc(150px + 10vw);
        height: calc(112.5px + 7.5vw);
        background-color: rgb(255, 230, 153);
        clip-path: polygon(64% 0, 9% 100%, 100% 0);
        position: relative;
        left: calc(150px + 10vw - (150px + 10vw)/1.55);
        top: calc(-112.5px - 7.5vw);
    }

    nav #nav-bg #c {
        width:  calc(150px + 10vw + (150px + 10vw)*2);
        height: calc(112.5px + 7.5vw);
        background-color: rgb(45, 54, 59);
        clip-path: polygon(46% 0, 26% 66%, 100% 0);
        position: relative;
        left: calc((-150px - 10vw)/35);
        top: calc((-112.5px - 7.5vw)*2);
    }

    nav #nav-bg #d {
        width:  calc(150px + 10vw + (150px + 10vw)*2);
        height: calc(112.5px + 7.5vw);
        background-color: rgb(255, 230, 153);
        clip-path: polygon(46% 0, 19% 25%, 100% 0);
        position: relative;
        left: calc((150px + 10vw) + (150px + 10vw)/1.8);
        top: calc((-112.5px - 7.5vw)*3);
    }

    nav #nav-bg #logo {
        position: relative;
        top: calc((-112.5px - 7.5vw)*3.9);
        left: calc(150px + 10vw - (150px + 10vw)/1.1);
    }

    nav #nav-bg #logo img {
        width: calc((150px + 10vw)/2.1);
    }

    main {
        position: absolute;
        top: calc(112.5px + 7.5vw);
        width: 100vw;
        z-index: 1;
    }

    main .info {
        color: var(--dark-text);
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 15px;
        padding-bottom: 40px;
    }

    main .info .subtitle-page {
        font-size: 9pt;
    }

    main .info .detail {
        background-color: rgb(80, 95, 104);
        color: white;
        margin-top: 15px;
        text-align: center;
        margin-bottom: 15px;
        border-left: 10px solid rgb(255, 230, 153);
        padding-top: 20px;
        padding-bottom: 30px;
    }

    main .info .detail i {
        color: rgb(255, 221, 119);
        font-size: 50pt;
        margin-top: 15px;
    }

    main .info .detail h2 {
        color: rgb(255, 221, 119);
        font-size: 15pt;
        margin-bottom: 10px;
    }

    main .info .detail h3 {
        background-color: rgb(45, 54, 59);
        padding-top: 11px;
        padding-bottom: 11px;
        margin-top: 10px;
        margin-bottom: 10px;
    }

    main .info .detail p {
        font-size: 11pt;
        width: calc(100% - 30px);
        margin: auto;
    }

    main .info .detail .st {
        font-size: 9.5pt;
        margin-bottom: 5px;
    }

    main .info .detail div p {
        font-size: 10.5pt;
        font-family: Courier;
        background-color: rgb(124, 134, 141);
        padding-top: 10px;
        padding-bottom: 10px;
        width: calc(100% - 30px);
        margin: auto;
    }

    main .info .detail div {
        padding-bottom: 15px;
    }

    main .info #action-btn {
        background-color: rgb(41, 59, 70);
        padding: 15px 20px;
        text-decoration: none;
        color: white;
        font-weight: 600;
        font-size: 11pt;
        position: relative;
        display: block;
        text-align: center;
        margin-top: 25px;
        border-radius: 3px;
    }

    /* Footer */

    footer {
        background-color: #2F383E;
        height: 140px;
        width: 100%;
        text-shadow: none;
        color: #7D8F9C;
    }
  
    footer div {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 50px;
        padding-bottom: 15px;
        padding-top: 20px;
    }
  
    footer div a {
        text-decoration: none;
        color: #7D8F9C;
    }
  
    footer div i {
        margin: 0px 20px;
        font-size: 22px;
        cursor: pointer;
        transition: color 0.3s;
    }
  
    footer div i:hover {
        color: #DAE8F4;
    }
  
    footer p {
        text-align: center;
        font-size: 10pt;
    }

    /* Desktop */

    @media (min-width: 1336px) {

        main .info .detail {
            width: 30%;
            margin: auto;
        }

        main .info #action-btn {
            width: calc(30% - 30px);
            margin: auto;
            margin-top: 30px;
        }

        /* ScrollBar */

        ::-webkit-scrollbar-thumb {
            border-radius: 0px;
        }
    
        ::-webkit-scrollbar {
            width: 9px;
            height: 9px;
        }
    
        ::-webkit-scrollbar-track {
            background: transparent; 
        }
    
        ::-webkit-scrollbar-thumb {
            background: #576974;
        }
    
        ::-webkit-scrollbar-thumb:hover {
            background: var(--dark-text); 
        }

    }

}