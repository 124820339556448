@keyframes shape {
    0%{transform: scale(1); opacity: 0.6;}
    70%{transform: scale(1); opacity: 0.6;}
    100%{transform: scale(2); opacity: 0;}
}

@keyframes shape-hor {
    0%{transform: translateX(-200px);}
    100%{transform: translateX(100vw);;}
}
  
@keyframes stroke {
    100% {stroke-dashoffset: -400;}
}

@keyframes side-element {
    0%{transform: translateX(calc(0vw - 15vw));}
    100%{transform: translateX(calc(100vw + 15vw));}
}

@keyframes side-element-min {
    0%{transform: translateX(calc(0vw - 40vw));}
    100%{transform: translateX(calc(100vw + 30vw));}
}

.styleServicios {
    background-color: rgb(233, 237, 241);
    --header-extent-y: calc(62px + 7.5vw);
    --header-extent-x: calc(82px + 10vw);
    color: var(--dark-text);
    overflow-x: hidden;
    --title-1: 2.72rem;
    --title-2: 2.3rem;
    --title-3: 1.9rem;
    --text-1: 1.45rem;
    --text-2: 1.2rem;
    --text-3: 1.2rem;
    --icon-2: 2.8rem;
    --time-bg-ventajas: 10s;

    /* --- HEADER --- */
    nav {
        position: relative;
        height: var(--header-extent-y);
        overflow: hidden;
    }

    nav #nav-bg #a {
        width:  var(--header-extent-x);
        height: var(--header-extent-y);
        clip-path: polygon(0 0, 100% 0%, 45% 100%, 0 79%);
        background-color: rgb(45, 54, 59);
    }

    nav #nav-bg #b {
        width:  var(--header-extent-x);
        height: var(--header-extent-y);
        background-color: rgb(255, 230, 153);
        clip-path: polygon(64% 0, 9% 100%, 100% 0);
        position: relative;
        left: calc(var(--header-extent-x) - (var(--header-extent-x)/1.55));
        top: calc(-1 * var(--header-extent-y));
    }

    nav #nav-bg #c {
        width:  calc(var(--header-extent-x) + (var(--header-extent-x)*2));
        height: var(--header-extent-y);
        background-color: rgb(45, 54, 59);
        clip-path: polygon(46% 0, 26% 66%, 100% 0);
        position: relative;
        left: calc((-1 * var(--header-extent-x))/35);
        top: calc((-1 * var(--header-extent-y))*2);
    }

    nav #nav-bg #d {
        width:  calc(var(--header-extent-x) + (var(--header-extent-x)*2));
        height: var(--header-extent-y);
        background-color: rgb(255, 230, 153);
        clip-path: polygon(46% 0, 19% 25%, 100% 0);
        position: relative;
        left: calc(var(--header-extent-x) + (var(--header-extent-x)/1.8));
        top: calc((-1 * var(--header-extent-y))*3);
    }

    nav #nav-bg #logo {
        position: relative;
        top: calc((-1 * var(--header-extent-y)) * 3.9);
        left: calc(var(--header-extent-x) - (var(--header-extent-x)/1.1));
    }

    nav #nav-bg #logo img {
        width: calc(var(--header-extent-x)/2.1);
    }

    /* --- *** --- *** --- MAIN --- *** --- *** --- */
    main {
        position: absolute;
        top: var(--header-extent-y);
        width: 100vw;
    }

    /* --- #oferta --- */
    main #oferta {
        width: 100vw;
        height: calc(100vh - var(--header-extent-y));
        overflow: hidden;
    }

    main #oferta .bg-anim-container * {
        width:  calc(300px + 20vw);
        height: calc(100vh - var(--header-extent-y));
        background-color: rgb(255, 230, 153);
        position: absolute;
        right: 0;
        top: -100px;
    }

    main #oferta .bg-anim-container #e {
        clip-path: polygon(100% 0, 0 50%, 100% 100%);
        animation: shape 2.5s infinite linear;
    }

    main #oferta .bg-anim-container #f {
        clip-path: polygon(0 50%, 0 50%, 100% 100%, 100% 99%, 1% 50%, 99% 1%, 99% 100%, 100% 100%, 100% 39%, 100% 0);
        animation: shape 3.5s infinite linear;
    }

    main #oferta .bg-anim-container #g {
        clip-path: polygon(100% 0, 0 50%, 100% 100%);
    }

    main #oferta .image-container {
        height: calc(100vh - var(--header-extent-y));
        min-height: 500px;
        width: calc(300px + 20vw);
        position: relative;
        left: calc(100vw - (300px + 20vw));
        display: flex;
        justify-content: center;
        align-items: center;
    }

    main #oferta .image-container img {
        width: 90%;
    }

    main #oferta .info {
        height: calc(100vh - var(--header-extent-y));
        min-height: 500px;
        width: calc(100vw - (300px + 20vw) - 30px);
        position: relative;
        left: 0;
        top: calc(-100vh + var(--header-extent-y));
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        text-align: center;
        padding: 15px;
        padding-bottom: 40px;
    }

    main #oferta .info p {
        font-size: var(--text-1);
        color: var(--light);
        padding-bottom: 10px;
    }

    main #oferta .info h1 {
        font-size: var(--title-1);
        padding-bottom: 32px;
        color: var(--s-dark-text);
    }

    main #oferta .info .elements {
        display: flex;
        padding-bottom: 30px;
    }

    main #oferta .info .elements div {
        padding: 30px;
    }

    main #oferta .info .elements i {
        font-size: var(--icon-2);
    }

    main #oferta .info .elements h2 {
        font-size: var(--text-2);
    }

    main #oferta .info a {
        background-color: rgb(255, 223, 126);
        font-size: 1.2rem;
        padding: 15px 35px;
        padding-top: 11px;
        text-decoration: none;
        color: var(--s-dark-text);
        border-radius: 2px;

        i {
            font-size: 2.1rem;
            position: relative;
            top: 4px;
        }
    }

    main #oferta .info a:hover {
        filter: brightness(0.95);
    }

    /* --- #ventajas --- */
    main #ventajas {
        width: 100vw;
        height: 800px;
        background-color: var(--s-dark-text);
        overflow: hidden;
        position: relative;
        z-index: 2;
    }

    main #ventajas .bg-anim-container {
        position: absolute;
    }

    main #ventajas .bg-anim-container * {
        width:  7rem;
        height: 7rem;
        background-color: rgba(255, 255, 255, 0.09);
        clip-path: polygon(0 0, 0% 100%, 100% 50%);
        position: absolute;
        z-index: 1;
    }

    main #ventajas .bg-anim-container #i {
        animation: shape-hor calc(var(--time-bg-ventajas)*1) infinite linear;
    }

    main #ventajas .bg-anim-container #j {
        margin-top: 150px;
        animation: shape-hor calc(var(--time-bg-ventajas)*2) infinite linear;
    }

    main #ventajas .bg-anim-container #k {
        margin-top: 300px;
        animation: shape-hor calc(var(--time-bg-ventajas)*3) infinite linear;
    }

    main #ventajas .bg-anim-container #l {
        margin-top: 50px;
        animation: shape-hor calc(var(--time-bg-ventajas)*3.5) infinite linear;
    }

    main #ventajas .bg-anim-container #m {
        margin-top: 200px;
        animation: shape-hor calc(var(--time-bg-ventajas)*1.5) infinite linear;
    }

    main #ventajas .bg-anim-container #n {
        margin-top: 350px;
        animation: shape-hor calc(var(--time-bg-ventajas)*2.5) infinite linear;
    }

    main #ventajas .info {
        position: relative;
        z-index: 2;
        min-height: 750px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        flex-direction: column;
    }

    main #ventajas h2 {
        color: rgb(255, 230, 153);
        font-size: var(--text-2);
        padding-top: 40px;
    }

    main #ventajas h1 {
        color: #e5eaee;
        font-size: var(--title-2);
    }

    main #ventajas p {
        font-size: var(--text-1);
        color: #94a3af;
    }

    main #ventajas .detail {
        display: flex;
        justify-content: center;
        padding-top: 60px;
    }

    main #ventajas .detail .element {
        padding-left: 30px;
        padding-right: 30px;
        width: 33%;
    }

    main #ventajas .detail .element img {
        width: 200px;
        padding-bottom: 10px;
    }

    main #ventajas .detail .element i {
        font-size: 4rem;
        color: #94a3af;
        padding-bottom: 20px;
    }

    main #ventajas .detail .element h3 {
        font-size: var(--text-2);
        color: rgb(255, 230, 153);
    }

    main #ventajas .detail .element p {
        padding-top: 10px;
        font-size: calc(var(--text-2) - 0.07rem);
    }

    /* --- #forma --- */
    main #forma {
        width: 100vw;
        height: 900px;
        display: flex;
    }

    main #forma .image-container {
        width: calc(400px + 10vw);
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 40px;
    }

    main #forma .image-container img {
        width: 100%;
        position: relative;
        z-index: 2;
    }

    main #forma .bg-anim-container * {
        width: calc(400px + 10vw);
        height: 900px;
        background-color: #9ab0c4;
        position: absolute;
        left: 0;
        z-index: 1;
    }

    main #forma .bg-anim-container #o {
        clip-path: polygon(0 0, 0% 100%, 100% 50%);
        animation: shape 2.5s infinite linear;
    }

    main #forma .bg-anim-container #p {
        clip-path: polygon(99% 50%, 99% 50%, 100% 50%, 0 100%, 0 0, 100% 50%, 99% 50%, 99% 50%, 1% 1%, 1% 99%);
        animation: shape 3.5s infinite linear;
    }

    main #forma .bg-anim-container #q {
        clip-path: polygon(0 0, 0% 100%, 100% 50%);
    }

    main #forma .info {
        width: calc(100vw - (400px + 10vw + 80px) - 30px - 15%);
        padding-left: 15%;
        padding-right: 30px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        position: relative;
        z-index: 2;
    }

    main #forma .info h1 {
        font-size: var(--title-2);
        color: var(--s-dark-text);
        padding-bottom: 30px;
    }

    main #forma .info h2 {
        color: rgb(226, 196, 107);
        font-size: var(--text-2);
        line-height: var(--text-2);
    }

    main #forma .info p {
        font-size: var(--text-1);
    }

    main #forma .info i {
        font-size: 1.1rem;
        color: rgb(226, 196, 107);
    }

    main #forma .info .list div {
        padding-bottom: 25px;
    }

    main #forma .info .list div * {
        display: inline;
    }

    main #forma .info .list div p {
        padding-left: 20px;
    }

    /* --- #tecnologias --- */
    main #tecnologias {
        width: 100vw;
        padding-top: 15px;
        padding-bottom: calc(90px + 105px + ((2rem + 2vw)*2));
    }

    main #tecnologias h2 {
        color: var(--dark-text);
        font-weight: 400;
        font-size: calc(var(--text-1) - 0.1rem);
        text-align: center;
        padding-left: 15px;
        padding-right: 15px;
        padding-bottom: 35px;
    }

    main #tecnologias .top-side, main #tecnologias .down-side {
        width: 100%;
        margin-top: 15px;
        margin-bottom: 15px;
    }

    main #tecnologias .element {
        background-color: #dde6ee;
        padding-top: 20px;
        padding-bottom: 20px;
        width: 15vw;
        border-radius: 6px;
        position: absolute;
        transform: translateX(calc(100vw + 15vw));
    }

    main #tecnologias .top-side .element {
        animation: side-element 90s infinite linear;
        animation-direction: reverse;
    }

    main #tecnologias .top-side #a-e-ts {animation-delay: -115s;}
    main #tecnologias .top-side #b-e-ts {animation-delay: -103.75s;}
    main #tecnologias .top-side #c-e-ts {animation-delay: -92.5s;}
    main #tecnologias .top-side #d-e-ts {animation-delay: -81.25s;}
    main #tecnologias .top-side #e-e-ts {animation-delay: -70s;}
    main #tecnologias .top-side #f-e-ts {animation-delay: -58.75s;}
    main #tecnologias .top-side #g-e-ts {animation-delay: -47.5s;}
    main #tecnologias .top-side #h-e-ts {animation-delay: -36.25s;}

    main #tecnologias .down-side .element {
        margin-top: calc(55px + (2rem + 2vw));
        animation: side-element 90s infinite linear;
    }

    main #tecnologias .down-side #a-e-ds {animation-delay: -115s;}
    main #tecnologias .down-side #b-e-ds {animation-delay: -103.75s;}
    main #tecnologias .down-side #c-e-ds {animation-delay: -92.5s;}
    main #tecnologias .down-side #d-e-ds {animation-delay: -81.25s;}
    main #tecnologias .down-side #e-e-ds {animation-delay: -70s;}
    main #tecnologias .down-side #f-e-ds {animation-delay: -58.75s;}
    main #tecnologias .down-side #g-e-ds {animation-delay: -47.5s;}
    main #tecnologias .down-side #h-e-ds {animation-delay: -36.25s;}

    main #tecnologias .element i {
        width: 100%;
        text-align: center;
        font-size: calc(2rem + 2vw);
        color: var(--dark-text);
    }

    /* --- Design header --- */
    main #design-header {
        position: relative;
        z-index: 2;
        background-image: url("../../images/servicios/design.jpg");
        background-size: cover;
        background-attachment: fixed;
        background-repeat: no-repeat;
        background-position: center;
    }

    main #design-header text {
        font-size: 5rem;
    }

    main #design-header .text {
        fill: none;
        stroke-width: 2.3;
        stroke-linejoin: round;
        stroke-dasharray: 70 330;
        stroke-dashoffset: 0;
        -webkit-animation: stroke 8s infinite linear;
        animation: stroke 18s infinite linear;
    }
      
    main #design-header .text:nth-child(5n + 1) {
        stroke: rgb(255, 230, 153);
        -webkit-animation-delay: -1.2s;
        animation-delay: -3.6s;
    }
    main #design-header .text:nth-child(5n + 2) {
        stroke: white;
        -webkit-animation-delay: -2.4s;
        animation-delay: -7.2s;
    }
      
    main #design-header .text:nth-child(5n + 3) {
        stroke: rgb(255, 230, 153);
        -webkit-animation-delay: -3.6s;
        animation-delay: -10.8s;
    }
      
    main #design-header .text:nth-child(5n + 4) {
        stroke: rgb(255, 230, 153);
        -webkit-animation-delay: -4.8s;
        animation-delay: -14.4s;
    }
      
    main #design-header .text:nth-child(5n + 5) {
        stroke: white;
        -webkit-animation-delay: -6s;
        animation-delay: -18s;
    }

    /* --- Design --- */
    main #design .detail {
        width: 100vw;
        height: 780px;
    }

    main #design .detail .bg-image-container {
        width: 100%;
        height: 700px;
        position: absolute;
        z-index: 1;
        left: 50%;
        transform: translate(-50%, calc(-50% + 50px));
        margin-top: 340px;
        background-image: url("../../images/servicios/shape.png");
        background-size: contain;
        background-attachment: fixed;
        background-repeat: no-repeat;
        background-position: center;
    }

    main #design .detail .info {
        position: relative;
        z-index: 2;
        --aux-width: calc(-600px + 50%);
        width: calc(60% - var(--aux-width));
        left: calc(var(--aux-width) + 80px);
        height: 400px;
        top: 50%;
        transform: translateY(calc(-50% - 25px));
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    main #design .detail .info h1 {
        font-size: var(--title-3);
        color: var(--s-dark-text);
        text-align: center;
    }

    main #design .detail .info p {
        font-size: var(--text-2);
        text-align: center;
        padding-top: 25px;
        padding-bottom: 40px;
    }

    main #design .detail .info a {
        background-color: rgb(255, 223, 126);
        font-size: 1.2rem;
        padding: 15px 35px;
        padding-top: 11px;
        text-decoration: none;
        color: var(--s-dark-text);
        border-radius: 2px;

        i {
            font-size: 2.1rem;
            position: relative;
            top: 4px;
        }
    }

    main #design .detail .info a:hover {
        filter: brightness(0.95);
    }

    main #design .detail .image-container {
        position: relative;
        width: calc(100px + 25%);
        transform: translateY(calc(-50% - 50px));
        left: calc((calc(-600px + 50%)) + 690px + 10%);
        z-index: 3;
    }

    main #design .detail .image-container img {
        width: 100%;
    }

    /* --- Articulos --- */
    main #articulos {
        width: 100vw;
        display: flex;
        margin-bottom: 50px;
    }

    main #articulos .detail {
        width: 60%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    main #articulos .detail .info {
        padding: 15px;
    }

    main #articulos .detail .info p {
        font-size: var(--text-1);
        color: var(--light);
        padding-bottom: 10px;
    }

    main #articulos .detail .info p b {
        color: var(--dark-text);
    }

    main #articulos .detail .info h1 {
        font-size: var(--title-2);
        padding-bottom: 32px;
        color: var(--s-dark-text);
    }

    main #articulos .image-container {
        width: 40%;
    }

    main #articulos .image-container img {
        width: 100%;
    }

    /* --- Articulos --- */
    main #varied {
        width: 100vw;
        height: 100vh;
        display: flex;
        align-items: flex-end;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        background-image: url("../../images/servicios/cA.jpg");
        transition: background-image 0.7s ease-in-out;
        filter: grayscale(30%);
    }

    main #varied .ic-container {
        width: 100vw;
        background-color: rgba(0, 0, 0, 0.589);
        display: flex;
        justify-content: center;
        flex-direction: row;
        padding-top: 25px;
        padding-bottom: 25px;
    }

    main #varied .image-car {
        width: 8.5vh;
        height: 11.5vh;
        margin: 1.7vw;
        border-radius: 7px;
        cursor: pointer;
        transition: transform 0.3s;
        background-size: cover;
        background-repeat: no-repeat;
        filter: grayscale(100%);
    }

    main #varied .selected {
        transform: scale(1.35);
        filter: grayscale(0%) saturate(3);
    }

    main #varied #ic-a {
        background-image: url("../../images/servicios/cA.jpg");
    }

    main #varied #ic-b {
        background-image: url("../../images/servicios/cB.jpg");
    }

    main #varied #ic-c {
        background-image: url("../../images/servicios/cC.jpg");
    }

    main #varied #ic-d {
        background-image: url("../../images/servicios/cD.jpg");
    }

    main #varied .detail {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        width: calc(50% - 40px);
        height: 65vh;
        margin-top: 6vh;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    main #varied .detail h1 {
        color:rgb(233, 237, 241);
        font-size: var(--title-1);
        text-shadow: 0px 0px 7px var(--dark-text);
    }

    main #varied .detail p {
        padding-top: 30px;
        color:rgb(233, 237, 241);
        font-size: var(--text-1);
        text-shadow: 0px 0px 5px var(--s-dark-text);
        animation-delay: 0.4s;
    }

    /* --- Footer --- */
    main footer {
        width: 100vw;
        background-color: rgb(22, 27, 29);
    }

    main footer p {
        font-size: 0.95rem;
        color: #94a3af;
        text-align: center;
        padding-top: 8px;
        padding-bottom: 8px;
    }

    /* |-|-|-|-|-|-||-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-| */
    /* |-|-|-|-|-|-||-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-| */
    /* |-|-|-|-|-|-||-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-| */
    /* --- mobile --- */
    @media (max-width: 1100px) {
        main {
            --title-1: 2.15em;
            --title-2: 1.9rem;
            --title-3: 1.6rem;
            --text-1: 1.1rem;
            --text-2: 1rem;
            --text-3: 1.1rem;
            --icon-2: 2.3rem;
            --time-bg-ventajas: 5s;
            overflow-x: hidden;
            overflow-y: visible;
        }

        /*OFERTA*/
        main #oferta .image-container {
            width: 100vw;
            min-height: unset;
            height: unset;
            left: unset;
        }

        main #oferta .info {
            top: auto;
            width: calc(100vw - 30px);
            min-height: unset;
            height: unset;
        }

        main #oferta {
            height: unset;
        }

        main #oferta .bg-anim-container {
            justify-content: end;
        }

        main #oferta .info .elements {
            padding-top: 15px;
        }

        main #oferta .info .elements div {
            padding: 10px;
        }

        main #oferta .bg-anim-container * {
            height: calc((100vh - var(--header-extent-y))/1.5);
            top: 0;
        }

        /*VENTAJAS*/
        main #ventajas {
            height: unset;
        }

        main #ventajas .detail {
            flex-direction: column;
        }

        main #ventajas .info {
            padding-top: 10px;
        }

        main #ventajas .info p {
            padding-left: 15px;
            padding-right: 15px;
        }

        main #ventajas .detail .element {
            width: calc(100vw);
            padding: unset;
            padding-bottom: 30px;
        }

        main #ventajas .detail .element p {
            padding: 30px;
            padding-top: 10px;
        }

        /*FORMA*/
        main #forma {
            flex-direction: column;
            height: unset;
        }

        main #forma .image-container {
            width: calc(100% - 80px);
            justify-content: unset;
        }

        main #forma .info {
            padding-top: 10px;
            padding-bottom: 40px;
            padding-left: 15px;
            padding-right: 15px;
            width: 100%;
            justify-content: unset;
        }

        main #forma .bg-anim-container * {
            height: 70vh;
            width: 80%;
        }

        /*DESIGN HEADER*/
        main #design-header text {
            font-size: 9.5rem;
        }

        main #design-header .anim-text {
            padding-top: 50px;
            padding-bottom: 40px;
        }

        main #design-header .text {
            stroke-width: 5;
        }

        /*DESIGN*/
        main #design .detail {
            width: 100vw;
            height: unset;
        }

        main #design .detail .bg-image-container {
            width: 0;
            height: 0;
            position: unset;
            z-index: unset;
            left: unset;
            transform: unset;
            margin-top: unset;
            background-image: unset;
        }

        main #design .detail .info {
            position: unset;
            width: 100%;
            height: unset;
            display: flex;
            justify-content: unset;
            align-items: center;
            flex-direction: column;
            transform: unset;
            padding-top: 20px;
            padding-bottom: 20px;
        }

        main #design .detail .info h1 {
            padding-top: 30px;
            padding-left: 10px;
            padding-right: 10px;
            padding-bottom: 10px;
        }

        main #design .detail .info p {
            padding-top: 25px;
            padding-bottom: 50px;
            padding-left: 10px;
            padding-right: 10px;
        }

        main #design .detail .image-container {
            position: unset;
            width: 100%;
            transform: unset;
            padding-top: 80px;
            background-color: #acbecc;
            margin-top: 40px;
        }

        /*ARTICULOS*/
        main #articulos {
            flex-direction: column-reverse;
        }

        main #articulos .detail {
            width: 100%;
            padding-top: 20px;
        }

        main #articulos .image-container {
            width: 100%;
            padding-bottom: 20px;
        }

        /*TECNOLOGIAS*/
        main #tecnologias .element {
            width: 30vw;
        }

        main #tecnologias .top-side .element {
            animation: side-element-min 30s infinite linear;
            animation-direction: reverse;
        }
    
        main #tecnologias .top-side #a-e-ts {animation-delay: -115s;}
        main #tecnologias .top-side #b-e-ts {animation-delay: -107.5s;}
        main #tecnologias .top-side #c-e-ts {animation-delay: -100s;}
        main #tecnologias .top-side #d-e-ts {animation-delay: -92.5s;}
        main #tecnologias .top-side #e-e-ts {animation-delay: -85s;}
        main #tecnologias .top-side #f-e-ts {animation-delay: -77.5s;}
        main #tecnologias .top-side #g-e-ts {animation-delay: -70s;}
        main #tecnologias .top-side #h-e-ts {animation-delay: -62.5s;}
    
        main #tecnologias .down-side .element {
            animation: side-element-min 30s infinite linear;
        }
    
        main #tecnologias .down-side #a-e-ds {animation-delay: -115s;}
        main #tecnologias .down-side #b-e-ds {animation-delay: -107.5s;}
        main #tecnologias .down-side #c-e-ds {animation-delay: -100s;}
        main #tecnologias .down-side #d-e-ds {animation-delay: -92.5s;}
        main #tecnologias .down-side #e-e-ds {animation-delay: -85s;}
        main #tecnologias .down-side #f-e-ds {animation-delay: -77.5s;}
        main #tecnologias .down-side #g-e-ds {animation-delay: -70s;}
        main #tecnologias .down-side #h-e-ds {animation-delay: -62.5s;}

        /*VARIED*/
        main #varied .detail {
            width: calc(100% - 40px);
        }

        /*FOOTER*/
        main footer p {
            font-size: 0.7rem;
            color: #94a3af;
            text-align: center;
            padding-top: 8px;
            padding-bottom: 8px;
        }
    }

    /*---Desktop---*/
    @media (min-width: 1100px) {
        /* ScrollBar */

        ::-webkit-scrollbar-thumb {
            border-radius: 0px;
        }
    
        ::-webkit-scrollbar {
            width: 9px;
            height: 9px;
        }
    
        ::-webkit-scrollbar-track {
            background: transparent; 
        }
    
        ::-webkit-scrollbar-thumb {
            background: #576974;
        }
    
        ::-webkit-scrollbar-thumb:hover {
            background: var(--dark-text); 
        }
    }
}