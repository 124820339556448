@keyframes triangles {
    from {  -webkit-transform: rotate(0deg) translateX(7px) rotate(0deg); }
    to   {  -webkit-transform: rotate(360deg) translateX(7px) rotate(-360deg); }
}

.styleNewPage {
    width: 100vw;
    overflow-x: hidden;

    /* Desktop */
    @media (min-width: 760px) {
        header {
            background-color: black;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-left: 10vw;
            padding-right: 10vw;

            div {
                display: flex;
                align-items: center;

                img {
                    width: 50px;
                    margin-right: 40px;
                }

                a {
                    text-decoration: none;
                    color: white;
                    font-size: 10pt;
                    margin-right: 40px;

                    &:hover {
                        color: rgb(214, 214, 214);
                    }
                }
            }

            .contact-button {
                font-size: 10pt;
                cursor: pointer;
                border-radius: 50px;
                padding: 2px 17px;
                background-color: rgb(255, 230, 153);
                border: none;
                text-decoration: none;
                color: black;

                .bxs-user-circle {
                    display: none;
                }

                &:hover {
                    background-color: rgb(255, 218, 107);
                }
            }
        }

        main {
            #top {
                width: calc(100vw - 20vw);
                height: calc(100vh - 50px);
                background-image: url('../../images/inicio/top.png');
                background-position: right;
                background-size: cover;
                padding-left: 10vw;
                padding-right: 10vw;
                display: flex;
                align-items: center;

                .title-content {
                    background-color: rgba(0, 0, 0, 0.75);
                    width: fit-content;
                    padding-right: 40px;
                    padding-top: 10px;
                    padding-bottom: 10px;

                    h1 {
                        color: white;
                    }

                    i {
                        margin-top: 5px;
                        color: white;
                        font-size: 18pt;
                        margin-right: 15px;
                    }
                }

                .product-name {
                    position: absolute;
                    margin-top: -500px;
                    margin-left: 30vw;

                    span {
                        position: relative;
                        left: -70px;
                        color: black;
                        font-size: 42pt;
                        font-weight: 1000;
                        text-shadow: 2px 0 rgb(100, 136, 226), -2px 0 rgb(100, 136, 226), 0 1px rgb(100, 136, 226), 0 -2px rgb(100, 136, 226),
                        1px 1px rgb(100, 136, 226), -1px -1px rgb(100, 136, 226), 1px -1px rgb(100, 136, 226), -1px 1px rgb(100, 136, 226);
                    }

                    div{
                        display: flex;

                        .arrow {
                            width: 80px;
                            height: 80px;
                            background-color: rgb(100, 136, 226);
                            clip-path: polygon(0 0, 50% 0, 100% 50%, 50% 100%, 0 100%, 4% 97%, 48% 97%, 96% 50%, 48% 3%, 8% 3%, 56% 50%, 8% 97%, 4% 97%, 52% 50%);
                        }
                    }
                }

                .visual-help {
                    position: absolute;
                    color: rgb(255, 230, 153);
                    left: 50%;
                    transform: translateX(-50%);
                    top: calc(100vh - 170px);
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    overflow: hidden;

                    span {
                        font-size: 12pt;
                        margin-bottom: 40px;
                    }

                    div {
                        display: flex;
                        flex-direction: column;

                        i {
                            font-size: 60pt;
                            margin-top: -45px;
                        }
                    }
                }
            }

            #middle-1 {
                padding-left: 10vw;
                padding-right: 10vw;

                #s-middle-1 {
                    display: flex;
                    padding-top: 90px;
                    padding-bottom: 90px;
                    justify-content: center;
                    align-items: center;

                    img {
                        width: 30%;
                        pointer-events: none !important;
                        user-select: none !important;
                    }

                    div {
                        text-align: right;
                        margin-right: 35px;
                        position: relative;
                        margin-top: -30px;

                        h2 {
                            font-size: calc(clamp(0.313rem, 10.067vw - 2.915rem, 3.125rem));

                            span {
                                font-size: 12pt;
                                position: absolute;
                                margin-top: 5px;
                            }
                        }

                        p {
                            font-size: calc(clamp(0.821rem, 1.902vw + 0.125rem, 1.429rem));
                            max-width: 600px;
                        }
                    }

                    .triangles-left, .triangles-right {
                        position: absolute;
                        width: 12%;
                        max-width: 140px;
                        animation: 6s infinite normal linear;
                        animation-name: triangles;
                        pointer-events: none !important;
                        user-select: none !important;
                    }

                    .triangles-left {
                        margin-top: -24%;
                        left: 25%;
                    }

                    .triangles-right {
                        margin-top: 30%;
                        animation-direction: reverse;
                    }
                }
            }

            #middle-2 {
                padding-left: 10vw;
                padding-right: 10vw;

                #s-middle-2 {
                    overflow: hidden;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding-top: 10px;
                    padding-bottom: 20px;

                    .images-container {
                        width: 90vw;
                        max-width: 33vw;
                        overflow: hidden;

                         img {
                            width: 100%;
                            pointer-events: none !important;
                            user-select: none !important;
                         }

                         .gamepad {
                            width: 30vw;
                            position: absolute;
                            margin-left: -16%;
                            margin-top: 10%;
                            pointer-events: none !important;
                            user-select: none !important;
                         }
                    }

                    .text-container {
                        margin-left: 25px;

                        p {
                            font-size: calc(clamp(0.821rem, 1.902vw + 0.125rem, 1.429rem));
                        }

                        span {
                            color: rgb(91, 91, 91);
                            width: 70%;
                            text-align: right;
                            margin-top: 20px;
                            float: right;
                            font-size: calc(clamp(0.5rem, 1.119vw + 0.09rem, 0.857rem));
                        }
                    }

                    .triangles-left {
                        position: absolute;
                        width: 12%;
                        max-width: 140px;
                        animation: 6s infinite normal linear;
                        animation-name: triangles;
                        pointer-events: none !important;
                        user-select: none !important;
                        margin-top: 340px;
                        margin-left: 280px;
                    }
                }
            }

            #middle-3 {
                padding-left: 10vw;
                padding-right: 10vw;
                padding-top: 120px;
                padding-bottom: 60px;

                #s-middle-3 {
                    overflow: hidden;

                    .text-container {
                        position: absolute;
                        margin-left: 5%;
                        margin-top: 3%;

                        h2 {
                            text-align: right;
                            font-size: calc(clamp(0.313rem, 10.067vw - 2.915rem, 3.125rem));
                            text-shadow: 2px 0 white, -2px 0 white, 0 1px white, 0 -2px white,
                        1px 1px white, -1px -1px white, 1px -1px white, -1px 1px white;
                        }

                        p {
                            font-size: calc(clamp(0.821rem, 1.902vw + 0.125rem, 1.429rem));
                            max-width: 600px;
                            text-align: right;
                            text-shadow: 2px 0 white, -2px 0 white, 0 1px white, 0 -2px white,
                        1px 1px white, -1px -1px white, 1px -1px white, -1px 1px white;

                            i {
                                font-style: normal;
                                font-size: 9pt;
                                position: relative;
                                top: -11px;
                                color: rgb(91, 91, 91);
                            }
                        }
                    }

                    img {
                        width: 70%;
                        float: right;
                        pointer-events: none !important;
                        user-select: none !important;
                    }

                    span {
                        position: absolute;
                        margin-top: 40%;
                        font-size: calc(clamp(0.5rem, 1.119vw + 0.09rem, 0.857rem));
                        right: 10%;
                        color: rgb(91, 91, 91);
                    }
                }
            }

            #middle-4 {
                padding-left: 10vw;
                padding-right: 10vw;
                background-color: rgb(230, 234, 238);
                padding-top: 40px;
                padding-bottom: 50px;
                
                .header {
                    padding-bottom: 30px;

                    h2 {
                        font-size: 24pt;
                    }

                    p {
                        font-size: 12pt;
                        position: relative;
                        top: -10px;
                    }
                }

                .card-container {
                    display: flex;
                    overflow: hidden;
                    justify-content: space-between;

                    #card-combate {
                        .title {
                            text-shadow: 2px 0 rgb(238, 238, 65), -2px 0 rgb(238, 238, 65), 0 1px rgb(238, 238, 65), 0 -2px rgb(238, 238, 65),
                            1px 1px rgb(238, 238, 65), -1px -1px rgb(238, 238, 65), 1px -1px rgb(238, 238, 65), -1px 1px rgb(238, 238, 65);
                        }
                    }

                    #card-poomsae {
                        .title {
                            text-shadow: 2px 0 rgb(201, 43, 43), -2px 0 rgb(201, 43, 43), 0 1px rgb(201, 43, 43), 0 -2px rgb(201, 43, 43),
                            1px 1px rgb(201, 43, 43), -1px -1px rgb(201, 43, 43), 1px -1px rgb(201, 43, 43), -1px 1px rgb(201, 43, 43);
                        }
                    }

                    #card-circuito {
                        .title {
                            text-shadow: 2px 0 rgb(47, 96, 221), -2px 0 rgb(47, 96, 221), 0 1px rgb(47, 96, 221), 0 -2px rgb(47, 96, 221),
                            1px 1px rgb(47, 96, 221), -1px -1px rgb(47, 96, 221), 1px -1px rgb(47, 96, 221), -1px 1px rgb(47, 96, 221);
                        }
                    }

                    .card {
                        width: calc(33.3% - 20px);

                        .title {
                            position: absolute;
                            color: black;
                            font-size: 21pt;
                            margin-left: 10px;
                        }

                        img {
                            width: 100%;
                            cursor: pointer;
                            user-select: none !important;
                        }

                        div {
                            display: flex;
                            flex-direction: column;
                            margin-top: 10px;

                            span {
                                font-size: 10pt;

                                b {
                                    font-size: 18pt;
                                    position: relative;
                                    top: 3px;
                                    margin-right: 10px;
                                }
                            }
                        }
                    }
                }
            }

            #middle-5 {
                padding-left: 10vw;
                padding-right: 10vw;
                padding-top: 100px;
                padding-bottom: 40px;

                h2 {
                    font-size: 24pt;
                    text-align: center;
                    margin-bottom: 30px;
                }

                .reviews-container {
                    overflow: auto;
                    white-space: nowrap;

                    div {
                        display: inline-block;
                        
                        iframe {
                            height: 300px;
                        }
                    }
                }
            }

            #middle-6 {
                padding-left: 10vw;
                padding-right: 10vw;
                padding-top: 100px;
                padding-bottom: 100px;

                h2 {
                    font-size: 24pt;
                    text-align: center;
                    margin-bottom: 10px;
                }

                .options {
                    margin: auto;
                    background-color: rgb(255, 230, 153);;
                    border-radius: 50px;
                    width: fit-content;
                    display: flex;
                    overflow: hidden;

                    .selected {
                        background-color: rgb(45, 54, 59);
                        color: white;
                    }

                    p {
                        font-size: 11pt;
                        padding: 10px 25px;
                        font-weight: 900;
                        cursor: pointer;
                        user-select: none !important;

                        &:hover {
                            background-color: rgb(27, 86, 111);
                            color: white;
                        }
                    }
                }

                .cards-container {
                    display: flex;
                    margin-top: 80px;
                    justify-content: space-around;

                    .card {
                        border: 4px solid rgb(45, 54, 59);
                        width: calc(33.33% - 20px - 60px);
                        padding: 20px;
                        border-radius: 20px;

                        .info {
                            display: flex;
                            flex-direction: column;
                            
                            span {
                                margin-bottom: 10px;
                                font-size: 10pt;

                                b {
                                    position: relative;
                                    font-size: 26pt;
                                    top: 6px;
                                }
                            }
                        }

                        .price {
                            position: absolute;
                            background-color: rgb(151, 210, 255);
                            padding: 11px 32px;
                            border-radius: 50px;
                            margin-top: -60px;
                            margin-left: -60px;

                            p {
                                font-size: 15pt;
                                font-weight: 900;
                            }

                            span {
                                position: absolute;
                                margin-top: -30px;
                                font-size: 10pt;
                            }
                        }

                        img {
                            height: 130px;
                            margin-left: 50%;
                            transform: translateX(-50%);
                            padding-bottom: 15px;
                            pointer-events: none !important;
                            user-select: none !important;
                        }
    
                        .nota {
                            position: absolute;
                            margin-left: 160px !important;
                            margin-top: 4px !important;
                            width: 100px !important;
                            font-size: 8.5pt !important;
                        }
                    }
                }

                @media (max-width: 1200px) {
                    .cards-container {
                        display: block;

                        .card {
                            margin: auto;
                            margin-bottom: 100px;
                            width: calc(80% - 20px - 60px);
                        }
                    }
                }
            }

            #middle-7 {
                padding-left: 10vw;
                padding-right: 10vw;
                padding-top: 50px;
                padding-bottom: 50px;
                background-color: rgb(45, 54, 59);
                overflow: hidden;

                h2 {
                    font-size: 24pt;
                    text-align: center;
                    margin-bottom: 10px;
                    color: white;
                }

                .cards-container {
                    width: 100%;
                    display: flex;
                    margin-top: 25px;
                    margin-bottom: 50px;

                    .card {
                        width: calc(33.3% - 20px);
                        padding: 0px 10px;
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        .paso {
                            padding-right: 20px;
                            color: rgb(45, 54, 59);
                            font-size: 80pt;
                            font-weight: 900;
                            text-shadow: 1px 0 rgb(151, 210, 255), -1px 0 rgb(151, 210, 255), 0 1px rgb(151, 210, 255), 0 -1px rgb(151, 210, 255),
                            1px 1px rgb(151, 210, 255), -1px -1px rgb(151, 210, 255), 1px -1px rgb(151, 210, 255), -1px 1px rgb(151, 210, 255);
                        }

                        div {
                            width: calc(100% - 40pt);

                            .normal {
                                color: white;
                                font-size: 9pt;
                                margin-bottom: 10px;

                                i {
                                    font-style: normal;
                                    font-size: 7.5pt;
                                }
                            }

                            a {
                                font-size: 9pt;
                                text-decoration: none;
                                color: rgb(255, 230, 153);
                                text-align: center;
                                display: block;
                                overflow-wrap: break-word;
                                margin-bottom: 10px;

                                i {
                                    font-size: 14pt;
                                    padding-right: 5px;
                                    position: relative;
                                    top: 2px;
                                }

                                &:hover {
                                    color: rgb(255, 218, 107);
                                }
                            }

                            .nota {
                                font-size: 7pt;
                                text-align: right;
                                color: white;
                                opacity: 0.8;
                            }
                        }

                        @media (max-width: 1200px) {
                            display: block;

                            div {
                                width: calc(100% - 20px);
                                margin: auto;
                            }
                        }
                    }
                }

                .contact-button {
                    background-color: rgb(255, 230, 153);
                    text-decoration: none;
                    padding: 10px 30px;
                    font-size: 12pt;
                    border-radius: 50px;
                    margin: auto;
                    color: rgb(45, 54, 59);
                    display: block;
                    width: fit-content;
                    transition: transform 100ms linear;

                    i {
                        font-size: 17pt;
                        margin-right: 7px;
                        position: relative;
                        top: 3px;
                    }

                    &:hover {
                        background-color: rgb(255, 218, 107);
                        transform: scale(1.1);
                    }
                }
            }
        }
    }

    /*-------------------------------------------------------------------------------------------------------------------------------*/
    /*-------------------------------------------------------------------------------------------------------------------------------*/
    /*-------------------------------------------------------------------------------------------------------------------------------*/
    /*-------------------------------------------------------------------------------------------------------------------------------*/
    /*-------------------------------------------------------------------------------------------------------------------------------*/
    /*-------------------------------------------------------------------------------------------------------------------------------*/
    /*-------------------------------------------------------------------------------------------------------------------------------*/

    /* Mobile */
    @media (max-width: 760px) {
        header {
            background-color: black;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-left: 5px;
            padding-right: 5px;

            div {
                display: flex;
                align-items: center;

                img {
                    width: 65px;
                    margin-right: 40px;
                }

                a {
                    text-decoration: none;
                    color: white;
                    font-size: 10pt;
                    margin-right: 40px;

                    &:hover {
                        color: rgb(214, 214, 214);
                    }
                }
            }

            .contact-button {
                cursor: pointer;
                border-radius: 50px;
                padding: 1px 3px;
                display: flex;
                background-color: transparent;
                border: none;
                text-decoration: none;

                .bxs-user-circle {
                    font-size: 30pt;
                    color:rgb(255, 230, 153);
                }

                span {
                    display: none;
                }

                &:hover {
                    i {
                        color: rgb(255, 218, 107);
                    }
                }
            }
        }

        main {
            width: 100vw !important;
            overflow-x: hidden !important;

            #top {
                width: calc(100vw - 10px);
                height: calc(100vh - 120px);
                background-image: url('../../images/inicio/top.png');
                background-position: right;
                background-size: cover;
                padding-left: 5px;
                padding-right: 5px;
                display: flex;
                align-items: start;
                justify-content: center;

                .title-content {
                    width: fit-content;
                    padding-right: 40px;
                    padding-top: 30px;
                    padding-bottom: 10px;

                    h1 {
                        font-size: 18pt;
                        color: white;
                    }

                    i {
                        margin-top: 5px;
                        color: white;
                        font-size: 18pt;
                        margin-right: 15px;
                    }
                }

                .product-name {
                    display: none;
                }

                .visual-help {
                    position: absolute;
                    color: rgb(255, 230, 153);
                    left: 50%;
                    transform: translateX(-50%);
                    top: calc(100vh - 200px);
                    display: flex;
                    flex-direction: column;
                    align-items: center;

                    span {
                        display: none;
                    }

                    div {
                        display: flex;
                        flex-direction: column;

                        i {
                            font-size: 60pt;
                            margin-top: -45px;
                            text-shadow: 2px 0 rgb(100, 136, 226), -2px 0 rgb(100, 136, 226), 0 1px rgb(100, 136, 226), 0 -2px rgb(100, 136, 226),
                        1px 1px rgb(100, 136, 226), -1px -1px rgb(100, 136, 226), 1px -1px rgb(100, 136, 226), -1px 1px rgb(100, 136, 226);
                        }
                    }
                }
            }

            #middle-1 {
                width: calc(100% - 40px);
                padding-left: 20px;
                padding-right: 20px;

                #s-middle-1 {
                    width: 100%;
                    display: flex;
                    padding-top: 150px;
                    padding-bottom: 90px;
                    justify-content: center;
                    align-items: center;

                    img {
                        width: 50%;
                        pointer-events: none !important;
                        user-select: none !important;
                    }

                    div {
                        text-align: right;
                        margin-right: 35px;
                        position: relative;
                        margin-top: -30px;

                        h2 {
                            font-size: 24pt;

                            span {
                                font-size: 10pt;
                                position: absolute;
                                margin-top: 5px;
                            }
                        }

                        p {
                            font-size: 12pt;
                        }
                    }

                    .triangles-left, .triangles-right {
                        position: absolute;
                        width: 20%;
                        max-width: 140px;
                        animation: 6s infinite normal linear;
                        animation-name: triangles;
                        pointer-events: none !important;
                        user-select: none !important;
                    }

                    .triangles-left {
                        margin-top: -90%;
                        left: 10%;
                    }

                    .triangles-right {
                        margin-top: 80%;
                        animation-direction: reverse;
                    }
                }
            }

            #middle-2 {
                padding-left: 10vw;
                padding-right: 10vw;
                padding-top: 30px;

                #s-middle-2 {
                    overflow: hidden;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    padding-top: 0px;
                    padding-bottom: 20px;

                    .images-container {
                        width: 70vw;
                        overflow: hidden;
                        margin-bottom: 30px;

                         img {
                            width: 100%;
                            pointer-events: none !important;
                            user-select: none !important;
                         }

                         .gamepad {
                            width: 50vw;
                            position: absolute;
                            margin-left: -35%;
                            margin-top: 25%;
                            pointer-events: none !important;
                            user-select: none !important;
                         }
                    }

                    .text-container {
                        margin-left: 25px;

                        p {
                            font-size: 12pt;
                        }

                        span {
                            color: rgb(91, 91, 91);
                            width: 70%;
                            text-align: right;
                            margin-top: 10px;
                            float: right;
                            font-size: calc(clamp(0.5rem, 1.119vw + 0.09rem, 0.857rem));
                        }
                    }

                    .triangles-left {
                        position: absolute;
                        width: 20%;
                        max-width: 140px;
                        animation: 6s infinite normal linear;
                        animation-name: triangles;
                        pointer-events: none !important;
                        user-select: none !important;
                        margin-top: 450px;
                        margin-left: 200px;
                    }
                }
            }

            #middle-3 {
                padding-left: 10vw;
                padding-right: 10vw;
                padding-top: 120px;
                padding-bottom: 60px;

                #s-middle-3 {
                    overflow: hidden;

                    .text-container {

                        h2 {
                            text-align: right;
                            font-size: 24pt;
                            text-align: center;
                        }

                        p {
                            font-size: 12pt;
                            max-width: 600px;
                            text-align: right;
                            text-align: center;

                            i {
                                font-style: normal;
                                font-size: 9pt;
                                position: relative;
                                top: -11px;
                                color: rgb(91, 91, 91);
                            }
                        }
                    }

                    img {
                        width: 100%;
                        pointer-events: none !important;
                        user-select: none !important;
                    }

                    span {
                        font-size: calc(clamp(0.5rem, 1.119vw + 0.09rem, 0.857rem));
                        color: rgb(91, 91, 91);
                        float: right;
                    }
                }
            }

            #middle-4 {
                padding-left: 15px;
                padding-right: 15px;
                width: calc(100vw - 30px);
                background-color: rgb(230, 234, 238);
                padding-top: 40px;
                padding-bottom: 50px;
                
                .header {
                    padding-bottom: 30px;

                    h2 {
                        font-size: 24pt;
                    }

                    p {
                        font-size: 12pt;
                        position: relative;
                        top: -10px;
                    }
                }

                .card-container {
                    display: flex;
                    overflow: hidden;
                    flex-direction: column;
                    justify-content: space-between;

                    #card-combate {
                        .title {
                            text-shadow: 2px 0 rgb(238, 238, 65), -2px 0 rgb(238, 238, 65), 0 1px rgb(238, 238, 65), 0 -2px rgb(238, 238, 65),
                            1px 1px rgb(238, 238, 65), -1px -1px rgb(238, 238, 65), 1px -1px rgb(238, 238, 65), -1px 1px rgb(238, 238, 65);
                        }
                    }

                    #card-poomsae {
                        .title {
                            text-shadow: 2px 0 rgb(201, 43, 43), -2px 0 rgb(201, 43, 43), 0 1px rgb(201, 43, 43), 0 -2px rgb(201, 43, 43),
                            1px 1px rgb(201, 43, 43), -1px -1px rgb(201, 43, 43), 1px -1px rgb(201, 43, 43), -1px 1px rgb(201, 43, 43);
                        }
                    }

                    #card-circuito {
                        .title {
                            text-shadow: 2px 0 rgb(47, 96, 221), -2px 0 rgb(47, 96, 221), 0 1px rgb(47, 96, 221), 0 -2px rgb(47, 96, 221),
                            1px 1px rgb(47, 96, 221), -1px -1px rgb(47, 96, 221), 1px -1px rgb(47, 96, 221), -1px 1px rgb(47, 96, 221);
                        }
                    }

                    .card {
                        width: calc(100% - 20px);
                        margin: auto;

                        .title {
                            position: absolute;
                            color: black;
                            font-size: 21pt;
                            margin-left: 10px;
                        }

                        img {
                            width: 100%;
                            user-select: none !important;
                            cursor: pointer;
                        }

                        div {
                            display: flex;
                            flex-direction: column;
                            margin-top: 0px;
                            margin-bottom: 20px;

                            span {
                                font-size: 10pt;

                                b {
                                    font-size: 18pt;
                                    position: relative;
                                    top: 3px;
                                    margin-right: 10px;
                                }
                            }
                        }
                    }
                }
            }

            #middle-5 {
                padding-left: 10vw;
                padding-right: 10vw;
                padding-top: 80px;
                padding-bottom: 0px;

                h2 {
                    font-size: 24pt;
                    text-align: center;
                    margin-bottom: 30px;
                }

                .reviews-container {
                    overflow: auto;
                    white-space: nowrap;

                    div {
                        display: inline-block;
                        
                        iframe {
                            height: 500px;
                        }
                    }
                }
            }

            #middle-6 {
                padding-left: 5px;
                padding-right: 5px;
                padding-top: 100px;
                padding-bottom: 0px;

                h2 {
                    font-size: 24pt;
                    text-align: center;
                    margin-bottom: 10px;
                }

                .options {
                    margin: auto;
                    background-color: rgb(255, 230, 153);;
                    border-radius: 50px;
                    width: fit-content;
                    display: flex;
                    overflow: hidden;

                    .selected {
                        background-color: rgb(45, 54, 59);
                        color: white;
                    }

                    p {
                        font-size: 9pt;
                        text-align: center;
                        padding: 10px 25px;
                        font-weight: 900;
                        cursor: pointer;
                        user-select: none !important;

                        &:hover {
                            background-color: rgb(27, 86, 111);
                            color: white;
                        }
                    }
                }

                .cards-container {
                    display: flex;
                    margin-top: 80px;
                    justify-content: space-between;

                    .card {
                        border: 4px solid rgb(45, 54, 59);
                        width: calc(33.33% - 20px - 60px);
                        padding: 20px;
                        border-radius: 20px;

                        .info {
                            display: flex;
                            flex-direction: column;
                            
                            span {
                                margin-bottom: 10px;
                                font-size: 10pt;

                                b {
                                    position: relative;
                                    font-size: 26pt;
                                    top: 6px;
                                }
                            }
                        }

                        .price {
                            position: absolute;
                            background-color: rgb(151, 210, 255);
                            padding: 11px 32px;
                            border-radius: 50px;
                            margin-top: -60px;
                            margin-left: -60px;

                            p {
                                font-size: 15pt;
                                font-weight: 900;
                            }

                            span {
                                position: absolute;
                                margin-top: -30px;
                                font-size: 10pt;
                            }
                        }

                        img {
                            height: 130px;
                            margin-left: 50%;
                            transform: translateX(-50%);
                            padding-bottom: 15px;
                            pointer-events: none !important;
                            user-select: none !important;
                        }
    
                        .nota {
                            position: absolute;
                            margin-left: 160px !important;
                            margin-top: 4px !important;
                            width: 100px !important;
                            font-size: 8.5pt !important;
                        }
                    }
                }

                @media (max-width: 1200px) {
                    .cards-container {
                        display: flex;
                        flex-direction: column-reverse;

                        .card {
                            margin: auto;
                            margin-bottom: 100px;
                            width: calc(90% - 20px - 60px);
                        }
                    }
                }
            }

            #middle-7 {
                padding-left: 10vw;
                padding-right: 10vw;
                padding-top: 50px;
                padding-bottom: 50px;
                background-color: rgb(45, 54, 59);
                overflow: hidden;

                h2 {
                    font-size: 24pt;
                    text-align: center;
                    margin-bottom: 10px;
                    color: white;
                }

                .cards-container {
                    width: 100%;
                    display: block;
                    margin-top: 25px;
                    margin-bottom: 50px;

                    .card {
                        margin: auto;
                        width: calc(100% - 20px);
                        padding: 0px 10px;
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        .paso {
                            padding-right: 20px;
                            color: rgb(45, 54, 59);
                            font-size: 80pt;
                            font-weight: 900;
                            text-shadow: 1px 0 rgb(151, 210, 255), -1px 0 rgb(151, 210, 255), 0 1px rgb(151, 210, 255), 0 -1px rgb(151, 210, 255),
                            1px 1px rgb(151, 210, 255), -1px -1px rgb(151, 210, 255), 1px -1px rgb(151, 210, 255), -1px 1px rgb(151, 210, 255);
                        }

                        div {
                            .normal {
                                color: white;
                                font-size: 9pt;
                                margin-bottom: 10px;

                                i {
                                    font-style: normal;
                                    font-size: 7.5pt;
                                }
                            }

                            a {
                                font-size: 9pt;
                                text-decoration: none;
                                color: rgb(255, 230, 153);
                                text-align: center;
                                display: block;
                                overflow-wrap: break-word;
                                margin-bottom: 10px;

                                i {
                                    font-size: 14pt;
                                    padding-right: 5px;
                                    position: relative;
                                    top: 2px;
                                }

                                &:hover {
                                    color: rgb(255, 218, 107);
                                }
                            }

                            .nota {
                                font-size: 7pt;
                                text-align: right;
                                color: white;
                                opacity: 0.8;
                            }
                        }

                        @media (max-width: 1200px) {
                            display: block;
                        }
                    }
                }

                .contact-button {
                    background-color: rgb(255, 230, 153);
                    text-decoration: none;
                    padding: 10px 30px;
                    font-size: 12pt;
                    border-radius: 50px;
                    margin: auto;
                    color: rgb(45, 54, 59);
                    display: block;
                    width: fit-content;

                    i {
                        font-size: 17pt;
                        margin-right: 7px;
                        position: relative;
                        top: 3px;
                    }

                    &:hover {
                        background-color: rgb(255, 218, 107);
                    }
                }
            }
        }
    }
}