.styleClientes {
    /* --- DESKTOP --- */
    @media (min-width: 1000px) {
        ::-webkit-scrollbar-thumb { border-radius: 7px; }
        ::-webkit-scrollbar { width: 11px; height: 11px; }
        ::-webkit-scrollbar-track { background: transparent; }
        ::-webkit-scrollbar-thumb { background: #576974; }
        ::-webkit-scrollbar-thumb:hover { background: #283035 !important; }

        #root {
            width: 100vw;
            height: 100vh;
            overflow: hidden;
            display: flex;
        }

        #main {
            width: calc(100vw - 260px);
            overflow-y: auto;
        }

        /* --- SIDEBAR --- */
        #sidebar {
            width: 260px;
            height: 100vh;
            overflow: hidden;
            background-color: #1d2327;

            .top {
                i {
                    display: none;
                }
    
                img {
                    width: 70px;
                    margin-top: 20px;
                }
            }

            .cliente {
                font-size: 8pt;
                font-weight: 600;
                color: rgb(238, 241, 242);
                margin-top: 30px;
                padding-left: 25px;
                padding-right: 25px;
            }

            .nombre {
                font-size: 11pt;
                font-weight: 100;
                color: rgb(238, 241, 242);
                padding-left: 25px;
                padding-right: 25px;
                margin-top: 0px;
            }

            .correo {
                font-size: 8pt;
                color: rgb(198, 208, 212);
                padding-left: 25px;
                padding-right: 25px;
                margin-top: 0px;
                margin-bottom: 40px;
            }

            .menuOption {
                width: calc(100% - 70px);
                margin: auto;
                display: block;
                text-decoration: none;
                color: rgb(238, 241, 242);
                padding: 11px 25px;
                font-size: 10.5pt;
                border-radius: 40px;

                i {
                    margin-right: 12px;
                    font-size: 12pt;
                }

                &:hover {
                    background-color: #343f44;
                }
            }

            .selected {
                background-color: rgb(142, 197, 239);
                color: rgb(29, 86, 130);

                &:hover {
                    background-color: rgb(142, 197, 239);
                    color: rgb(32, 108, 167);
                }
            }

            button {
                width: 300px;
                padding: 15px 25px;
                padding-top: 16px;
                padding-bottom: 14px;
                text-align: left;
                position: absolute;
                bottom: 0 !important;
                font-size: 10pt;
                cursor: pointer;
                outline: none;
                border: none;
                background-color: rgb(52, 67, 76);
                color: rgb(238, 241, 242);

                i {
                    font-size: 12pt;
                    margin-right: 7px;
                }

                &:hover {
                    background-color: rgb(76, 102, 117);
                }
            }
        }

        /* --- COMPRAS --- */
        #productos {
            background-image: url(../../images/clientes/fondoLogin.jpg);
            background-size: cover;
            background-repeat: no-repeat;
            background-position: bottom;
            height: 200px;
            padding: 50px;

            #header {
                width: 100%;
                display: flex;
                justify-content: space-between;

                div {
                    h1 {
                        font-size: 19pt;
                        font-weight: 100;
                        line-height: 20pt;
                        color: rgb(45, 54, 59);
                    }

                    p {
                        font-size: 9pt;
                        color: rgb(45, 54, 59);
                    }
                }

                a {
                    font-size: 10.5pt;
                    text-decoration: underline;
                    color: rgb(125, 144, 155);

                    i {
                        margin-right: 7px;
                        font-size: 11pt;
                    }

                    &:hover {
                        color: rgb(39, 59, 70);
                    }
                }
            }

            #carousel {
                margin-top: 40px;
                width: 100%;
                height: 11vw;
                display: flex;
                justify-content: space-between;

                .item {
                    width: 28%;
                    height: 100%;
                    background-size: contain;
                    background-repeat: no-repeat;
                    background-position: center;
                    /*background: black !important;*/
                }

                #ci-1 {
                    background-image: url(../../images/clientes/moduloCombate.png);
                }

                #ci-2 {
                    background-image: url(../../images/clientes/moduloPoomase.png);
                }

                #ci-3 {
                    background-image: url(../../images/clientes/moduloCircuito.png);
                }
            }
        }

        #compras {
            margin-top: calc(6vw - 70px);
            padding: 50px;
            padding-bottom: 0;

            #header {
                width: 100%;

                h1 {
                    font-size: 19pt;
                    font-weight: 100;
                    line-height: 20pt;
                    color: rgb(45, 54, 59);
                }

                p {
                    font-size: 9pt;
                    color: rgb(45, 54, 59);
                }
            }

            #tablaCompras {
                width: calc(100% - 40px);
                height: 40vh;
                background-color: rgb(238, 242, 245);
                margin-top: 30px;
                border-radius: 11px;
                padding: 20px;
                overflow: auto;
                margin-bottom: 20px;

                td span, th span {
                    display: block;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }

                @media (min-width: 1000px) {
                    .plataforma-th, .plataforma-td, .version-th, .version-td, .fecha-th, .fecha-td {
                        display: none;
                    }

                    .producto-th {
                        width: 60%;
                    }

                    .botones-td, .botones-th {
                        width: 280px !important;
                    }
                }

                @media (min-width: 1200px) {
                    .version-th, .version-td, .fecha-th, .fecha-td {
                        display: table-cell;
                    }

                    .producto-th {
                        width: 30%;
                    }

                    .version-th {
                        width: 7%;
                    }

                    .botones-td, .botones-th {
                        width: 280px !important;
                    }
                }

                @media (min-width: 1300px) {
                    .plataforma-th, .plataforma-td {
                        display: table-cell;
                    }

                    .botones-td, .botones-th {
                        width: 280px !important;
                    }
                }

                table {
                    width: 100%;
                    table-layout: fixed;
                }

                th {
                    padding: 15px;
                    text-align: left;
                    font-size: 10pt;
                    color: rgb(45, 54, 59);
                }

                td {
                    padding: 15px;
                    font-size: 10pt;
                    color: rgb(45, 54, 59);
                    text-align: left;
                }

                button {
                    margin: 5px;
                    padding: 10px 20px;
                    font-size: 8pt;
                    font-weight: 400;
                    cursor: pointer;
                    outline: none;
                    border: none;
                    background-color: rgb(67, 81, 87);
                    color: white;
                    float: right;
                    border-radius: 4px;
                    
                    i {
                        margin-left: 7px;
                        font-size: 11pt;
                    }

                    &:hover {
                        background-color: rgb(26, 33, 36);
                    }
                }
            }
        }

        /* --- COPIAS --- */
        .show-copies {
            padding: 20px 15px;

            .closeButton:hover {
                color: rgb(40, 161, 226);
            }

            .title {
                font-size: 19pt;
                font-weight: 100;
                line-height: 20pt;
                color: rgb(45, 54, 59);
                text-align: left;
            }

            .htmlContainer {
                p {
                    font-size: 9.5pt;
                    color: rgb(45, 54, 59);
                    text-align: left;
                }

                div {
                    margin-top: 25px;
                    height: 380px;
                    border-radius: 7px;
                    background-color: rgb(230, 234, 238);
                    overflow: auto;
                    padding: 20px;

                    table {
                        width: 100%;
                    }

                    th {
                        padding: 15px;
                        text-align: left;
                        font-size: 10pt;
                        color: rgb(45, 54, 59);
                    }
    
                    td {
                        padding: 15px;
                        font-size: 10pt;
                        color: rgb(45, 54, 59);
                        text-align: left;
                    }
    
                    button {
                        margin: 5px;
                        padding: 10px 20px;
                        font-size: 8pt;
                        font-weight: 400;
                        cursor: pointer;
                        outline: none;
                        border: none;
                        background-color: rgb(67, 81, 87);
                        color: white;
                        float: right;
                        border-radius: 4px;
                        
                        i {
                            margin-left: 7px;
                            font-size: 11pt;
                        }
    
                        &:hover {
                            background-color: rgb(26, 33, 36);
                        }
                    }
                }
            }

            .footer {
                text-align: right;
                font-size: 8pt;
                font-style: italic;
                padding-right: 28.8px;
            }
        }

        /* --- VER CLAVE --- */
        .show-key-container {
            padding: 0 !important;
            background: rgb(251, 251, 251);

            #bg-key {
                width: 100vw;
                height: 100vh;
                background-image: url(../../images/clientes/fondoActivacion.png);
                background-size: contain;
                background-repeat: no-repeat;
                background-position: right;
                position: absolute;
                top: 0;
                left: 0;
                z-index: 2 !important;
            }
        }

        .show-key {
            padding: 50px 50px;
            height: 100vh;
            border-radius: 0;
            display: block !important;
            background-color: rgba(251, 251, 251, 0);
            overflow: hidden;
            overflow-y: auto;
            z-index: 3 !important;

            .closeButton {
                position: absolute;
                left: 27px;
                top: 36px;
                outline:none !important;
                outline-width: 0 !important;
                box-shadow: none !important;
                -moz-box-shadow: none !important;
                -webkit-box-shadow: none !important;
                border-color: transparent !important;
                color: rgb(45, 54, 59);

                &:hover {
                    color: rgb(40, 161, 226);
                }
            }

            .title {
                padding: 0;
                width: calc((100% - 500px) - 40px);
                padding-left: 40px;
                color: rgb(45, 54, 59);

                .titleContainer {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    justify-content: space-between;

                    h1 {
                        font-size: 21pt;
                        font-weight: 100;
                        line-height: 20pt;
                        color: rgb(45, 54, 59);
                        text-align: left;
                    }

                    .linkVideo {
                        text-align: right;

                        p {
                            font-size: 9pt;
                            color: rgb(158, 166, 180);
                            font-weight: 100;
                        }
        
                        a {
                            font-size: 9pt;
                            color: rgb(0, 98, 172);
        
                            i {
                                font-size: 18px;
                                position: relative;
                                top: 3px;
                                margin-right: 5px;
                            }

                            &:hover{
                                color: rgb(29, 135, 216);
                            }
                        }
                    }   
                }
            }

            .htmlContainer {
                padding: 0;
                width: 100%;
                margin: 0;

                #agradecimientos {
                    text-align: left;
                    margin-top: 10px;
                    border-left: 1px solid rgb(183, 188, 197);
                    padding-left: 40px;
                    width: calc((100% - 500px) - 40px);

                    p {
                        font-size: 10pt;
                        color: rgb(45, 54, 59);
                    }

                    img {
                        margin-top: 20px;
                        width: 500px;
                    }
                }

                #clave {
                    text-align: left;
                    margin-top: 40px;
                    border-left: 1px solid rgb(183, 188, 197);
                    padding-left: 40px;
                    width: calc((100% - 500px) - 40px);

                    p {
                        font-size: 10pt;
                        color: rgb(45, 54, 59);
                    }

                    .key-container {
                        display: flex;
                        margin-top: 20px;
                        margin-bottom: 50px;

                        div {
                            padding-top: 15px;
                            padding-bottom: 15px;
                            background-color: rgb(225, 227, 231);
                            border-radius: 7px;

                            p {
                                font-family: Consolas;
                                color: rgb(109, 116, 129);
                                font-size: 12pt;
                                padding-left: 30px;
                                padding-right: 30px;
                            }
                        }

                        button {
                            padding: 5px 17px;
                            border: none;
                            outline: none;
                            margin-left: 6px;
                            cursor: pointer;
                            background-color: rgb(53, 59, 77);
                            border-radius: 7px;

                            i {
                                font-size: 16pt;
                                color: white;
                            }

                            &:hover {
                                background-color: rgb(78, 95, 141);
                            }
                        }
                    }
                }
            }
        }

        /* --- SOPORTE TÉCNICO --- */
        #soporte {
            padding: 50px;
            overflow: hidden;

            h1 {
                font-size: 21pt;
                font-weight: 100;
                line-height: 20pt;
                color: rgb(45, 54, 59);
            }

            h2 {
                font-size: 12pt;
                font-weight: 900;
                line-height: 20pt;
                color: rgb(151, 210, 255);
            }

            p {
                font-size: 10pt;
                margin-bottom: 30px;
                padding-top: 15px;
            }

            .question-container {
                background-color: rgb(230, 234, 238);
                font-size: 10pt;
                padding: 25px;
                margin: 25px 0px;
                border-radius: 7px;

                ol, ul {
                    list-style-type: none;
                    color: rgb(45, 54, 59);
                }

                p {
                    color: rgb(45, 54, 59);
                    font-size: 10pt;
                    margin-bottom: unset;
                    padding-top: unset;
                }

                div {
                    display: flex;
                    justify-content: space-between;
                    margin-bottom: 15px;

                    h3 {
                        color: rgb(45, 54, 59);
                    }

                    a {
                        font-size: 9pt;
                        color: rgb(0, 98, 172);
    
                        i {
                            font-size: 18px;
                            position: relative;
                            top: 3px;
                            margin-right: 5px;
                        }

                        &:hover{
                            color: rgb(29, 135, 216);
                        }
                    }
                }
            }

            .contact-card {
                background-color: rgb(45, 54, 59);
                width: 270px;
                height: 80px;
                margin: 10px;
                float: left;
                padding: 15px;
                display: flex;
                justify-content: space-between;
                border-radius: 10px;

                &:hover {
                    background-color: rgb(151, 210, 255);
                }

                &:hover .icons i {
                    color: rgb(45, 54, 59) !important;
                }

                &:hover .links a {
                    color: rgb(45, 54, 59) !important;
                    font-weight: 700;
                }

                .icons {
                    display: flex;
                    height: 100%;
                    justify-content: center;
                    align-items: center;
                    font-size: 18pt;

                    i {
                        color: aliceblue;
                        padding: 10px;
                    }
                }

                .links {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;

                    a {
                        width: 100%;
                        text-align: center;
                        padding-left: 20px;
                        padding-right: 20px;
                        padding-top: 5px;
                        padding-bottom: 5px;
                        color: aliceblue;
                        text-decoration: none;
                        font-size: 10pt;
                    }
                }
            }
        }
    }

    /* --- MOBILE --- */
    @media (max-width: 1000px) {
        /* --- SIDEBAR --- */
        #sidebar {
            width: 100% !important;
            height: 80px;
            overflow: hidden;
            background-color: rgb(45, 54, 59);
            transition: height 0.3s linear;

            .top {
                height: 80px;
                padding-left: 25px;
                padding-right: 25px;
                display: flex;
                justify-content: space-between;

                i {
                    font-size: 21pt;
                    line-height: 80px;
                    color: white;
                }
    
                img {
                    width: auto;
                    padding: 10px;
                }
            }

            .cliente {
                font-size: 8pt;
                font-weight: 600;
                color: rgb(238, 241, 242);
                padding-left: 25px;
                padding-right: 25px;
                margin-top: 15px;
            }

            .nombre {
                font-size: 11pt;
                font-weight: 100;
                color: rgb(238, 241, 242);
                padding-left: 25px;
                padding-right: 25px;
            }

            .correo {
                font-size: 8pt;
                color: rgb(198, 208, 212);
                padding-left: 25px;
                padding-right: 25px;
                margin-bottom: 20px;
            }

            .menuOption {
                width: calc(100% - 50px);
                display: block;
                text-decoration: none;
                color: rgb(238, 241, 242);
                padding: 15px 25px;
                font-size: 11pt;

                i {
                    margin-right: 12px;
                    font-size: 14pt;
                }

                &:hover {
                    background-color: rgb(77, 102, 112);
                }
            }

            .selected {
                background-color: rgb(67, 81, 87);
            }

            button {
                width: 100%;
                padding: 15px 25px;
                padding-top: 16px;
                padding-bottom: 14px;
                text-align: left;
                font-size: 10pt;
                cursor: pointer;
                outline: none;
                border: none;
                background-color: rgb(105, 126, 137);
                color: rgb(238, 241, 242);
                margin-top: 40px;

                i {
                    font-size: 12pt;
                    margin-right: 7px;
                }

                &:hover {
                    background-color: rgb(76, 102, 117);
                }
            }
        }

        .open {
            height: 363px !important;
        }

        /* --- COMPRAS --- */
        #productos {
            background-image: url(../../images/clientes/fondoLogin.jpg);
            background-size: cover;
            background-repeat: no-repeat;
            background-position: bottom;
            height: 200px;
            padding: 50px 15px;

            #header {
                width: 100%;
                display: flex;
                justify-content: space-between;

                div {
                    h1 {
                        font-size: 19pt;
                        font-weight: 100;
                        line-height: 20pt;
                        color: rgb(45, 54, 59);
                    }

                    p {
                        font-size: 9pt;
                        color: rgb(45, 54, 59);
                    }
                }

                a {
                    font-size: 9pt;
                    text-decoration: underline;
                    color: rgb(125, 144, 155);

                    i {
                        margin-right: 5px;
                        font-size: 9pt;
                    }

                    &:hover {
                        color: rgb(39, 59, 70);
                    }
                }
            }

            #carousel {
                margin-top: 20px;
                width: 100%;
                overflow-x: auto;
                white-space: nowrap;

                &::-webkit-scrollbar { display: none !important; }

                .item {
                    display: inline-block;
                    width: 280px;
                    height: 160px;
                    margin-right: 10px;
                    background-size: contain;
                    background-repeat: no-repeat;
                    background-position: center;
                    /*background: black !important;*/
                }

                #ci-1 {
                    background-image: url(../../images/clientes/moduloCombate.png);
                }

                #ci-2 {
                    background-image: url(../../images/clientes/moduloPoomase.png);
                }

                #ci-3 {
                    background-image: url(../../images/clientes/moduloCircuito.png);
                }
            }
        }

        #compras {
            margin-top: 0px;
            padding: 20px 15px;

            #header {
                width: 100%;

                h1 {
                    font-size: 19pt;
                    font-weight: 100;
                    line-height: 20pt;
                    color: rgb(45, 54, 59);
                }

                p {
                    font-size: 9pt;
                    color: rgb(45, 54, 59);
                }
            }

            #tablaCompras {
                width: calc(100% - 20px);
                background-color: rgb(238, 242, 245);
                margin-top: 20px;
                border-radius: 11px;
                padding: 10px 10px;
                overflow: auto;
                margin-bottom: 20px;

                th span {
                    display: block;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }

                .plataforma-th, .plataforma-td, .version-th, .version-td {
                    display: none;
                }

                table {
                    width: 100%;
                    table-layout: fixed;
                }

                th {
                    padding: 15px 5px;
                    text-align: left;
                    font-size: 10pt;
                    color: rgb(45, 54, 59);
                }

                td {
                    padding: 15px 5px;
                    font-size: 10pt;
                    color: rgb(45, 54, 59);
                    text-align: left;
                }

                button {
                    margin: 5px;
                    padding: 10px 10px;
                    font-size: 7pt;
                    font-weight: 400;
                    cursor: pointer;
                    outline: none;
                    border: none;
                    background-color: rgb(67, 81, 87);
                    color: white;
                    float: right;
                    border-radius: 7px;
                    
                    i {
                        margin-left: 7px;
                        font-size: 12.5pt;
                    }
                }
            }
        }

        /* --- COPIAS --- */
        .show-copias-container {
            &::-webkit-scrollbar { display: none !important; }
        }

        .show-copies {
            padding: 20px 0px;

            .hide-on-mobile {
                display: none !important;
            }

            .closeButton:hover {
                color: rgb(40, 161, 226);
            }

            .title {
                font-size: 19pt;
                font-weight: 100;
                line-height: 20pt;
                color: rgb(45, 54, 59);
                text-align: left;
                padding-left: 15px;
            }

            .htmlContainer {
                margin-left: 15px;
                margin-right: 15px;

                p {
                    font-size: 9.5pt;
                    color: rgb(45, 54, 59);
                    text-align: left;
                }

                div {
                    margin-top: 25px;
                    height: 350px;
                    border-radius: 7px;
                    background-color: rgb(230, 234, 238);
                    overflow: auto;
                    padding: 5px;

                    table {
                        width: 100%;
                    }

                    th {
                        padding: 15px;
                        text-align: left;
                        font-size: 10pt;
                        color: rgb(45, 54, 59);
                    }
    
                    td {
                        padding: 15px;
                        font-size: 10pt;
                        color: rgb(45, 54, 59);
                        text-align: left;
                    }
    
                    button {
                        margin: 5px;
                        padding: 10px 10px;
                        font-size: 7pt;
                        font-weight: 400;
                        cursor: pointer;
                        outline: none;
                        border: none;
                        background-color: rgb(67, 81, 87);
                        color: white;
                        float: right;
                        border-radius: 7px;
                        
                        i {
                            margin-left: 7px;
                            font-size: 12.5pt;
                        }
    
                        &:hover {
                            background-color: rgb(26, 33, 36);
                        }
                    }
                }
            }

            .footer {
                text-align: right;
                font-size: 8pt;
                font-style: italic;
                padding-right: 28.8px;
            }
        }

        /* --- VER CLAVE --- */
        .show-key-container {
            padding: 0 !important;
            background: rgb(251, 251, 251);
            
            &::-webkit-scrollbar { display: none !important; }

            #bg-key {
                display: none !important;
            }
        }

        .show-key {
            padding: 50px 20px;
            border-radius: 0;
            display: block !important;
            background-color: rgba(251, 251, 251, 0);

            .closeButton {
                position: absolute;
                left: 0px;
                top: 40px;
                outline:none !important;
                outline-width: 0 !important;
                box-shadow: none !important;
                -moz-box-shadow: none !important;
                -webkit-box-shadow: none !important;
                border-color: transparent !important;
                color: rgb(45, 54, 59);

                &:hover {
                    color: rgb(40, 161, 226);
                }
            }

            .title {
                padding: 0;
                width: calc(100% - 30px);
                padding-left: 30px;
                color: rgb(45, 54, 59);

                .titleContainer {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    justify-content: space-between;

                    h1 {
                        font-size: 20pt;
                        font-weight: 100;
                        line-height: 20pt;
                        color: rgb(45, 54, 59);
                        text-align: left;
                    }

                    .linkVideo {
                        text-align: right;

                        p {
                            font-size: 8pt;
                            color: rgb(158, 166, 180);
                            font-weight: 100;
                        }
        
                        a {
                            font-size: 8pt;
                            color: rgb(0, 98, 172);
        
                            i {
                                font-size: 16px;
                                position: relative;
                                top: 3px;
                                margin-right: 5px;
                            }

                            &:hover{
                                color: rgb(29, 135, 216);
                            }
                        }
                    }   
                }
            }

            .htmlContainer {
                padding: 0;
                width: 100%;
                margin: 0;
                overflow-x: hidden;

                #agradecimientos {
                    text-align: left;
                    margin-top: 10px;
                    border-left: 1px solid rgb(183, 188, 197);
                    padding-left: 30px;
                    width: calc(100% - 30px);

                    p {
                        font-size: 10pt;
                        color: rgb(45, 54, 59);
                    }

                    img {
                        margin-top: 20px;
                        width: 100%;
                    }
                }

                #clave {
                    text-align: left;
                    margin-top: 40px;
                    border-left: 1px solid rgb(183, 188, 197);
                    padding-left: 30px;
                    width: calc(100% - 30px);

                    p {
                        font-size: 10pt;
                        color: rgb(45, 54, 59);
                    }

                    .key-container {
                        display: flex;
                        margin-top: 20px;
                        margin-bottom: 50px;

                        div {
                            padding-top: 15px;
                            padding-bottom: 15px;
                            background-color: rgb(225, 227, 231);
                            border-radius: 7px;

                            p {
                                font-family: Consolas;
                                color: rgb(109, 116, 129);
                                font-size: 9pt;
                                padding-left: 30px;
                                padding-right: 30px;
                                text-align: center;
                            }
                        }

                        button {
                            padding: 5px 17px;
                            border: none;
                            outline: none;
                            margin-left: 6px;
                            cursor: pointer;
                            background-color: rgb(53, 59, 77);
                            border-radius: 7px;

                            i {
                                font-size: 16pt;
                                color: white;
                            }

                            &:hover {
                                background-color: rgb(78, 95, 141);
                            }
                        }
                    }
                }
            }
        }

        /* --- SOPORTE TÉCNICO --- */
        #soporte {
            padding: 50px 15px;
            overflow: hidden;

            h1 {
                font-size: 21pt;
                font-weight: 100;
                line-height: 20pt;
                color: rgb(45, 54, 59);
            }

            h2 {
                font-size: 12pt;
                font-weight: 900;
                line-height: 20pt;
                color: rgb(151, 210, 255);
            }

            p {
                font-size: 10pt;
                margin-bottom: 30px;
                padding-top: 15px;
            }

            .question-container {
                background-color: rgb(230, 234, 238);
                font-size: 10pt;
                padding: 25px;
                margin: 25px 0px;
                border-radius: 7px;

                ol, ul {
                    list-style-type: none;
                    color: rgb(45, 54, 59);
                }

                p {
                    color: rgb(45, 54, 59);
                    font-size: 10pt;
                    margin-bottom: unset;
                    padding-top: unset;
                }

                div {
                    display: flex;
                    justify-content: space-between;
                    margin-bottom: 15px;

                    h3 {
                        color: rgb(45, 54, 59);
                        width: calc(100% - 80px);
                    }

                    a {
                        font-size: 8pt;
                        color: rgb(0, 98, 172);
    
                        i {
                            font-size: 16px;
                            position: relative;
                            top: 3px;
                            margin-right: 5px;
                        }

                        &:hover{
                            color: rgb(29, 135, 216);
                        }
                    }
                }
            }

            .contact-card {
                background-color: rgb(45, 54, 59);
                margin: auto;
                width: 270px;
                height: 70px;
                margin-top: 10px;
                padding: 10px;
                display: flex;
                justify-content: space-between;
                border-radius: 10px;

                &:hover {
                    background-color: rgb(151, 210, 255);
                }

                &:hover .icons i {
                    color: rgb(45, 54, 59) !important;
                }

                &:hover .links a {
                    color: rgb(45, 54, 59) !important;
                    font-weight: 700;
                }

                .icons {
                    display: flex;
                    height: 100%;
                    justify-content: center;
                    align-items: center;
                    font-size: 18pt;

                    i {
                        color: aliceblue;
                        padding: 10px;
                    }
                }

                .links {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;

                    a {
                        width: 100%;
                        text-align: center;
                        padding-left: 20px;
                        padding-right: 20px;
                        padding-top: 5px;
                        padding-bottom: 5px;
                        color: aliceblue;
                        text-decoration: none;
                        font-size: 10pt;
                    }
                }
            }
        }
    }
}