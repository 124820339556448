::-webkit-scrollbar-thumb { border-radius: 7px; }
::-webkit-scrollbar { width: 11px; height: 11px; }
::-webkit-scrollbar-track { background: #C2CED8; }
::-webkit-scrollbar-thumb { background: #576974; }
::-webkit-scrollbar-thumb:hover { background: #283035 !important; }
@keyframes loading-bounce {
    0% { transform: scale(1, 0.7); }
    40% { transform: scale(0.8, 1.2); }
    60% { transform: scale(1, 1); }
    100% { bottom: 140px; }
}

@keyframes loading-step {
    0% {
        box-shadow: 0 10px 0 rgba(0, 0, 0, 0),
        0 10px 0 #7fb1d7,
        -35px 50px 0 #7fb1d7,
        -70px 90px 0 #7fb1d7;
    }

    100% {
        box-shadow: 0 10px 0 #7fb1d7,
        -35px 50px 0 #7fb1d7,
        -70px 90px 0 #7fb1d7,
        -70px 90px 0 rgba(0, 0, 0, 0);
    }
}

.styleAdministracion {
    body {
        background-color: rgb(24, 27, 29);
        padding: 30px 7vw 30px 7vw;
    }

    .iconButtonTable {
        font-size: 11.5pt;
        transition: transform 0.1s linear;
        cursor: pointer;

        &:hover {
            transform: scale(1.3);
        }
    }

    .custom-button {
        padding: 7px 20px;
        font-size: 11pt;
        border-radius: 7px;
        cursor: pointer;
        border: none;
        outline: none;
        background-color: #C2CED8;
        
        i {
            margin-right: 5px;
        }

        &:hover {
            background-color: #89bae0;
            color: #1d2327;
        }
    }

    .circle-button {
        border-radius: 50%;
        padding: 8px 12px;
        font-size: 12pt;

        i {
            margin-right: 0px;
        }
    }

    .header-top {
        clear: both;
        overflow: hidden;
        display: flex;
        justify-content: space-between;
        align-items: center;

        div {
            display: flex;
            align-items: center;

            img {
                height: 80px;
                padding-right: 10px;
                border-right: 4px solid #ffe699;
            }
    
            span {
                font-size: 15pt;
                padding-left: 20px;
            }
        }
    }

    h1, span, p {
        color: #e6eaee;
        line-height: 100%;
    }

    #clientes-container {
        margin-top: 30px;
        background-color: rgb(77, 96, 107);
        border-radius: 10px;
        overflow: hidden;

        .header {
            display: flex;
            justify-content: space-between;
            padding: 30px;

            h1 {
                font-size: 20pt;
                color: #89bae0;
            }

            span {
                font-size: 10pt;
            }
        }

        .tables {
            min-height: calc(100vh - 300px);
            background-color: rgb(48, 55, 59);
        }

        .margin-table {
            padding-left: 47px;
        }

        .rdt_TableHead {
            font-size: 10pt;
            font-weight: 900;
        }

        .rdt_TableRow {
            &:hover {
                background-color: rgb(24, 27, 29);
                border-radius: 50px;
            }

            button {
                border-radius: 50px;

                &:focus {
                    background-color: unset;
                }

                &:hover {
                    background-color: rgb(75, 85, 92);
                }

                &:active {
                    background-color: rgb(90, 115, 133);
                }
            }

            .table-menu-button {
                background-color: transparent !important;
                color: white !important;
                border-radius: 50px !important;

                &:hover {
                    background-color: #4b555c !important;
                }
            }
        }

        .principal-table {
            color: white;

            .rdt_TableRow:has(+ .rdt_ExpanderRow) {
                button {
                    border-radius: 0px;
                    background-color: #89bae0;
                    color: rgb(24, 27, 29);
                }
            }
        }

        .secondary-table {
            border-left: 6px solid #89bae0 !important;
            .rdt_TableHead {
                color: #89bae0;
            }

            .rdt_TableRow:has(+ .rdt_ExpanderRow) {
                button {
                    border-radius: 0px;
                    background-color: #f1e6ca;
                    color: rgb(24, 27, 29);
                }
            }
        }

        .third-table {
            border-left: 6px solid #f1e6ca !important;
            .rdt_TableHead {
                color: #f1e6ca;
            }
        }
    }

    .registrar-cliente, .registrar-compra {
        background-color: #30373b;

        .MuiFormHelperText-root {
            color: #ff8c84;
        }

        .closeButton:hover {
            color: rgb(40, 161, 226);
        }

        .title {
            text-align: left;
            font-size: 18pt;
            padding-left: 95px;
            padding-top: 30px;
            color: #e6eaee;
        }

        div:has(> .confirmButton) {
            width: calc(100% - 190px);
            justify-content: end;

            .confirmButton {
                padding: 10px 25px;
                font-size: 11pt;
                border-radius: 7px;
                cursor: pointer;
                border: none;
                outline: none;
                background-color: #89bae0;
                color: rgb(24, 27, 29);

                &:hover {
                    background-color: #5da6df;
                }
            }
        }
    }

    .registrar-compra {
        .client-data {
            padding-left: 65px;
            padding-bottom: 20px;

            h3 {
                color: #e6eaee;
                font-size: 8.5pt;
                font-weight: 300;
                text-align: left;
                padding-bottom: 5px;
            }

            p {
                text-align: left;
                font-size: 11pt;
                font-weight: 900;
                padding-bottom: 3px;
            }

            span {
                display: block;
                text-align: left;
                font-size: 10pt;
            }
        }
    }

    .popup-loading {
        background-color: rgba(48, 55, 59, 0.7);
        border-radius: 20px 20px 70px 70px;
        overflow: hidden;

        .htmlContainer {
            padding-top: 100px;
            padding-bottom: 40px;
            transform: scale(1.2);
        }

        .title {
            color: white;
            font-size: 11.5pt;
            font-weight: 400;
            padding-bottom: 25px;
            padding-top: 25px;
            background-color: rgb(58, 67, 73);
        }

        .loader {
            position: relative;
            width: 120px;
            height: 90px;
            margin: 0 auto;
        }
        
        .loader:before {
            content: "";
            position: absolute;
            bottom: 30px;
            left: 50px;
            height: 30px;
            width: 30px;
            border-radius: 50%;
            background: #ffe699;
            animation: loading-bounce 0.5s ease-in-out infinite alternate;
        }
        
        .loader:after {
            content: "";
            position: absolute;
            right: 0;
            top: 0;
            height: 7px;
            width: 45px;
            border-radius: 4px;
            box-shadow: 0 5px 0 #7fb1d7, -35px 50px 0 #7fb1d7, -70px 95px 0 #7fb1d7;
            animation: loading-step 1s ease-in-out infinite;
        }
    }

    .ver-id {
        background-color: #30373b;
        border-radius: 5px;
        padding-left: 25px;
        padding-right: 25px;
        padding-top: 10px;
        padding-bottom: 10px;

        .closeButton:hover {
            color: rgb(40, 161, 226);
        }

        .title {
            text-align: left;
            font-size: 12.5pt;
            padding-left: 60px;
            padding-top: 30px;
            color: #e6eaee;
            font-weight: 600;
        }

        .id-container {
            display: flex;
            justify-content: center;
            padding-top: 30px;
            padding-bottom: 30px;

            span {
                display: block;
                width: 500px;
                padding: 10px;
                border-radius: 50px 0px 0px 50px;
                background-color: #576974;
            }

            button {
                cursor: pointer;
                height: 38px;
                border: none;
                outline: none;
                padding-left: 12px;
                padding-right: 12px;
                border-radius: 0px 50px 50px 0px;
                transition: background-color 0.2s linear, color 0.2s linear;

                i {
                    padding-top: 4px;
                    font-size: 17pt;
                }

                &:hover {
                    background-color: rgb(40, 161, 226);
                    color: white;
                }

                &:focus {
                    background-color: #ffe699;
                    color:rgb(24, 27, 29);
                }
            }
        }
    }

    .MuiPaper-root {
        background-color: #1e262e;

        span {
            font-size: 10.5pt;
        }
    }
}